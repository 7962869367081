import * as React from "react";
import { ProfileFormContext } from "../../ProfileEditForm";
import { ACTIONS } from "../ProfileFormReducer";
import { LexicalRichTextareaEditor } from "../../../FormComponents/LexicalRichTextareaEditor";
import DOMPurify from "dompurify";

const BlankEducationItem = {
  school_name: "",
  qualification: "",
  description: "",
  start_date: "",
  end_date: "",
  current: false,
};

export default function EducationHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);
  const [expandedIds, setExpandedIds] = React.useState([]);
  const newItemRef = React.useRef(null);

  const addEducationItem = () => {
    dispatch({
      type: ACTIONS.ADD_EDUCATION,
      payload: BlankEducationItem,
    });
    setExpandedIds([...expandedIds, state.profile.educations.length]);
    setTimeout(() => {
      newItemRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const removeEducationItem = (index: number) => {
    if (window.confirm("Are you sure you want to delete this education?")) {
      dispatch({
        type: ACTIONS.REMOVE_EDUCATION,
        payload: index,
      });
    }
  };

  const updateEducationItem = (index: number, education: any) => {
    dispatch({
      type: ACTIONS.UPDATE_EDUCATION,
      payload: { index, education },
    });
  };

  const toggleAccordion = (index) => {
    setExpandedIds((prev) =>
      prev.includes(index) ? prev.filter((id) => id !== index) : [...prev, index]
    );
  };

  return (
    <div className="accordion" id="educationAccordion">
      {state.profile.educations.map((education, index) => (
        <div
          className="accordion-item mb-3 border-0"
          key={index}
          ref={index === state.profile.educations.length - 1 ? newItemRef : null}
        >
          <h2 className="accordion-header d-flex justify-content-between align-items-center">
            <button
              className={`accordion-button ${!expandedIds.includes(index) ? 'collapsed' : ''} bg-light text-dark`}
              type="button"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: 'pointer' }}
            >
              {education.qualification || 'New Qualification'} at {education.school_name || 'New School'}
            </button>
            <button
              type="button"
              className="btn btn-link p-0 ms-2"
              onClick={(e) => {
                e.stopPropagation();
                removeEducationItem(index);
              }}
              style={{ color: 'grey' }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </h2>

          <div className={`accordion-collapse collapse ${expandedIds.includes(index) ? 'show' : ''}`}>
            <div className="accordion-body border bg-light">
              <EducationItem
                education={education}
                updateEducationItem={(edu) => updateEducationItem(index, edu)}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={addEducationItem}>
          Add Education
        </button>
      </div>
    </div>
  );
}

function EducationItem({ education, updateEducationItem }) {
  const editorKey = React.useMemo(() => `education-${education.id}-${Date.now()}`, []);
  const [educationState, setEducationState] = React.useState(education);

  const handleFieldUpdate = (field, value) => {
    const updatedState = { ...educationState, [field]: value };
    setEducationState(updatedState);
    updateEducationItem(updatedState);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    handleFieldUpdate(name, type === 'checkbox' ? checked : value);
  };

  const handleEditorChange = (newHtmlContent) => {
    handleFieldUpdate('description', newHtmlContent);
  };

  return (
    <div className="mt-4">
      <div className="form-group">
        <label htmlFor="school_name">School Name</label>
        <input
          type="text"
          className="form-control"
          id="school_name"
          name="school_name"
          value={educationState.school_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="qualification">Qualification</label>
        <input
          type="text"
          className="form-control"
          id="qualification"
          name="qualification"
          value={educationState.qualification}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="start_date">Start Date</label>
        <input
          type="date"
          className="form-control"
          id="start_date"
          name="start_date"
          value={educationState.start_date}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="end_date">End Date</label>
        <input
          type="date"
          className="form-control"
          id="end_date"
          name="end_date"
          value={educationState.end_date}
          onChange={handleInputChange}
          disabled={educationState.current}
        />
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="current"
          name="current"
          checked={educationState.current}
          onChange={handleInputChange}
        />
        <label className="form-check-label" htmlFor="current">
          Current
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <LexicalRichTextareaEditor
          key={editorKey}
          editorState={educationState.description || ''}
          onChangeDescription={handleEditorChange}
        />
      </div>
    </div>
  );
}

const EducationPreview = ({ education }) => {
  const {
    school_name,
    qualification,
    description,
    start_date,
    end_date,
    current,
  } = education;

  return (
    <div>
      <h5>
        <strong>{qualification}</strong>
      </h5>
      <h6>{school_name}</h6>
      <h6>
        {start_date} - {current ? "Present" : end_date}
      </h6>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
    </div>
  );
};