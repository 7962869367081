import * as React from "react";
import InviteProfile from "./InviteProfile";

import ProfileTags from "./ProfileComponents/ProfileTags";
import ProfileName from "./ProfileComponents/ProfileName";
import ProfileLocation from "./ProfileComponents/ProfileLocation";
import ProfileCompensation from "./ProfileComponents/ProfileCompensation";
import ProfileDistance from "./ProfileComponents/ProfileDistance";
import ProfileJobType from "./ProfileComponents/ProfileJobType";
import ProfileBadges from "./ProfileBadges";

import AddFavoriteCandidate from "../Favorites/AddFavoriteCandidate";
import AddBlockCandidate from "../Favorites/NewAddBlockCandidate";

import { ListContext } from './ProfileList'

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  zip: string;
  image: string;
  url: string;
  compensation: string | number;
  job_types: Array<any>;
  skills: Array<any>;
  position_types: Array<any>;
  badges: Array<any>;
}

interface Job {
  [prop: string]: any;
}

interface ProfileProps {
  profile: Profile;
  job?: any;
  user_id?: number;
  authenticity_token?: string;
  distance?: number;
  favorites?: Array<number>;
  blocked?: Array<number>;
  requested?: Array<number>;
  accepted?: Array<number>;
  declined?: Array<number>;
  addToRequestedFunction?: () => Array<any>;
  requestingAll?: boolean;
}

export default function Profile({
  profile,
  authenticity_token = "",
  favorites = [],
  blocked = [],
  job = {},
  requested = [],
  accepted = [],
  declined = [],
  addToRequestedFunction = undefined,
  distance = undefined,
  user_id = undefined,
  requestingAll = false
}: ProfileProps) {
  const [state, setState] = React.useState({
    blocked: false,
    loading: false,
    favorite: favorites.indexOf(profile.id) >= 0,
    requested: requested.indexOf(profile.id) >= 0,
    accepted: accepted.indexOf(profile.id) >= 0,
    declined: declined.indexOf(profile.id) >= 0
  });

  const blockedCallback = () => setState({ blocked: !state.blocked });
  const job_id = job ? job.id : undefined;
  const [context, setContext] = React.useContext(ListContext);
  const contextBlocked = context.blocked.includes(profile.id);
  // const badges = profile.badges || []
  const badges = [];

  const profile_favorited = favorites.includes(profile.id);
  const profile_blocked = blocked.includes(profile.id);

  React.useEffect(() => {
    setState({
      ...state,
      requested: requested.indexOf(profile.id) >= 0,
      accepted: accepted.indexOf(profile.id) >= 0,
      declined: declined.indexOf(profile.id) >= 0
    });
  }, [requested, accepted, declined]);

  const renderActionButtons = () => {
    if (state.loading || requestingAll) {
      return (
        <a className="button btn-dd-small">
          <i className="fad fa-spinner fa-spin"></i> Requesting...
        </a>
      );
    }

    if (state.requested || state.accepted) {
      return (
        <a className="button white btn-dd-small">
          <i className="fad fa-check"></i> {job && job.position_type == 'temp' ? "Requested" : "Invited"}
        </a>
      );
    }

    // ...rest of existing renderActionButtons code...
  };

  return (
    <div
      className={`field transition-profile ${state.blocked ? 'blocked' : ''} ${contextBlocked ? 'contextBlocked' : ''}`}
      key={profile.id} style={{ cursor: 'pointer' }}
      onClick={() => window.open(profile.url, "_blank")}
    >
      <li className="col-12 d-flex justify-content-between border-top border-start border-end py-2 pt-1 pb-3 border-radius-1 flex-wrap">
        <div className="col-2 p-3 d-none d-md-block" style={{ minHeight: 40 }}>
          <img src={profile.image} className="img-fluid rounded-circle" />
        </div>
        <div className="col-12 col-md-10 d-flex flex-column justify-content-center ps-4 ps-md-0 pe-4 pe-md-0">
          <div className="job-list-content">
            <div className="col-12 d-flex flex-row align-items-center pe-2">
              <div className="col-3 p-3 d-block d-md-none" style={{ minHeight: 40 }}>
                <img src={profile.image} className="img-fluid rounded-circle" />
              </div>
              <ProfileName
                blockedCallback={() => blockedCallback}
                favorites={favorites}
                blocked={blocked}
                profile={profile}
                job_id={job_id}
                user_id={user_id}
                authenticity_token={authenticity_token}
                invite_component={
                  job.hasOwnProperty('id') && (
                    <InviteProfile
                      requested={requested}
                      accepted={accepted}
                      declined={declined}
                      profile={profile}
                      job={job}
                      authenticity_token={authenticity_token}
                      callback={addToRequestedFunction}
                      requestingAll={requestingAll}
                    />
                  )
                }
              />
              <div className="d-none col-12 d-md-block">
                <div className="col-12 col-md-6 text-align-right mt-3 mt-md-0 d-md-flex justify-content-end">
                  <AddFavoriteCandidate
                    profile={profile}
                    favorited_init={profile_favorited}
                    authenticity_token={authenticity_token}
                  />
                  {(job_id || user_id) && <AddBlockCandidate
                    profile={profile}
                    job_id={job_id}
                    user_id={user_id}
                    favorited_init={profile_blocked}
                    authenticity_token={authenticity_token}
                    blockedCallback={blockedCallback}
                  />}
                  {job.hasOwnProperty('id') && (
                    <InviteProfile
                      requested={requested}
                      accepted={accepted}
                      declined={declined}
                      profile={profile}
                      job={job}
                      authenticity_token={authenticity_token}
                      callback={addToRequestedFunction}
                      requestingAll={requestingAll}
                    />
                  )}
                </div>
              </div>
            </div>
            {badges.length > 0 && (
              <div className="d-flex my-2 flex-wrap">
                <ProfileBadges badges={badges} />
              </div>
            )}
          </div>
        </div>
        <div className="col-12 d-flex my-2 flex-wrap px-4">
          {profile.job_types && profile.job_types.length > 1 &&
            profile.job_types.slice(0, 1).map((jt: string) => (
              <ProfileJobType job_type={jt} />
            ))
          }
          {profile.job_types && profile.job_types.length > 1 &&
            <>
              <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-md-none col-12">
                <i className="fa fa-plus"></i> {profile.job_types.length - 1} More Positions
              </span>
              <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
                <i className="fa fa-plus"></i> {profile.job_types.length - 1} More Positions
              </span>
            </>
          }
          {profile.job_types && profile.job_types.length < 2 &&
            profile.job_types.map((jt: string) => (
              <ProfileJobType job_type={jt} />
            ))}
          {profile.city && profile.state && profile.zip && (
            <ProfileLocation
              city={profile.city}
              state={profile.state}
              zip={profile.zip}
            />
          )}
          {profile.compensation && (
            <ProfileCompensation compensation={profile.compensation} />
          )}
          {distance && <ProfileDistance distance={distance} />}
        </div>
        <div className="col-12">
          <ProfileTags profile={profile} />
        </div>
        {/* </a> */}
      </li>
      <div className="clearfix"></div>
    </div>
  );
}
