import * as React from 'react';
import AutocompleteAddress from '../../FormComponents/AutocompleteAddress';
import PhotoPopup from './PhotoPopup';
import { LexicalRichTextareaEditor } from '../../FormComponents/LexicalRichTextareaEditor';
import { ProfileFormContext } from '../ProfileEditForm';
import { ACTIONS } from './ProfileFormReducer';

export default function GeneralInfo() {
  const [state, dispatch] = React.useContext(ProfileFormContext);
  const [htmlContent, setHtmlContent] = React.useState(state.profile.background || '');

  const setField = (e) => dispatch({
    type: ACTIONS.SET_FIELD,
    payload: { [`${e.target.name}`]: e.target.value }
  });

  const setAddress = (address) => dispatch({
    type: ACTIONS.SET_FIELD,
    payload: address
  });

  const handleEditorChange = (newHtmlContent: string) => {
    setHtmlContent(newHtmlContent);
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: { background: newHtmlContent }
    });
  };

  return (
    <div>
      <PhotoPopup />
      {/* <ProfilePhoto avatar={state.profile.avatar} /> */}

      {/* <h1>General Info</h1> */}
      <div className="row">
        {/* First Name Field */}
        <div className="form-group col-12">
          <label htmlFor="first_name" className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            name="first_name"
            value={state.profile.first_name || ""}
            onChange={setField}
          />
        </div>
        {/* Last Name Field */}
        <div className="form-group col-12">
          <label htmlFor="last_name" className="form-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="last_name"
            name="last_name"
            value={state.profile.last_name || ""}
            onChange={setField}
          />
        </div>

      </div>

      <div className="row">
        {/* Phone Field */}
        <div className="form-group col-8">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input
            type="text"
            className="form-control"
            id="phone"
            name="phone"
            value={state.profile.phone || ""}
            onChange={setField}
          />
        </div>

        {/* Extension */}
        <div className="form-group col-4">
          <label htmlFor="phone_ext" className="form-label">Extension</label>
          <input
            type="text"
            className="form-control"
            id="phone_ext"
            name="phone_ext"
            value={state.profile.phone_ext || ""}
            onChange={setField}
          />
        </div>

      </div>

      {/* AutocompleteAddress */}
      <AutocompleteAddress
        model={'profile'}
        street_address={state.profile.street_address}
        street_address_2={state.profile.street_address_2}
        city={state.profile.city}
        state={state.profile.state}
        zip={state.profile.zip}
        setAddress={setAddress}
      />

      <div>
        <h3>About Me</h3>
        <LexicalRichTextareaEditor
          editorState={htmlContent}
          onChangeDescription={handleEditorChange}
        />
      </div>
    </div>
  );
}
