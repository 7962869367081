import * as React from "react"
import JobForm from "./JobFormContext"
import DatePicker from "react-datepicker"
import "react-time-picker/dist/TimePicker.css"
import 'react-clock/dist/Clock.css'

import * as moment from "moment"
import * as Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css";

export default function GeneralInfo({
  practices,
  job_types,
  position_types,
  tabIndexFunc,
  validation,
  setValidation,
  temp = false,
  cancelButton
}) {
  const [jobState, setJobState] = React.useContext(JobForm)
  const [confidentialState, setConfidentialState] = React.useState(false)

  //this state makes sure error messages dont show unless someone is trying to procceed without filing out sections
  const [errorMessage, setErrorMessage] = React.useState(false)

  const updateJobState = (key, value) => setJobState(currentJobState => ({ ...currentJobState, [`${key}`]: value }))

  const selectConfidential = () => {
    setConfidentialState(!confidentialState)
    setJobState({ ...jobState, confidential: !confidentialState })
  }

  //this is the validation that prevents using the continue button
  const validationGI = () => {
    const {
      position_type,
      job_type_id,
      practice_id,
      sms_phone,
      temp_date,
      temp_start,
      temp_end,
    } = jobState

    setErrorMessage(true)

    const position_attributes_valid = position_type !== null && position_type !== "" &&
      job_type_id !== null && job_type_id !== "" &&
      practice_id !== null && practice_id !== ""

    const temp_attributes_valid = sms_phone !== null && sms_phone !== "" &&
      temp_date !== null && temp_date !== "" &&
      temp_start !== null && temp_start !== "" &&
      temp_end !== null && temp_end !== ""

    if (position_attributes_valid) {
      if (jobState.position_type !== "temp") {
        tabIndexFunc(1)
      } else {
        //these are the checks for the temp position
        if (temp_attributes_valid) {
          tabIndexFunc(1)
        }
      }
    }
  }

  //These three use effects listen to a change in date or time and will update the job state
  const tempDateHandler = e => setJobState(currentJobState => ({ ...currentJobState, ["temp_date"]: e }))
  const startTimeHandler = e => setJobState(currentJobState => ({ ...currentJobState, ["temp_start"]: e }))
  const endTimeHandler = e => setJobState(currentJobState => ({ ...currentJobState, ["temp_end"]: e }))

  //Listens to see if a person clicks off temp as a position type and will clear the values.
  // Prevents someone from choosing temp then changing mind
  React.useEffect(() => {
    if (jobState.position_type !== "temp") {
      setJobState(currentJobState => ({
        ...currentJobState,
        ["temp_date"]: null,
        ["temp_start"]: null,
        ["temp_end"]: null,
        ["sms_phone"]: null
      }))
    }

    if (jobState.position_type === "temp") {
      setConfidentialState(false)
      setJobState(currentJobState => ({
        ...currentJobState,
        ["confidential"]: false
      }))

      startTimeHandler(moment().utc().hour(8).minute(0).second(0).millisecond(0).toDate())
      endTimeHandler(moment().utc().hour(17).minute(0).second(0).millisecond(0).toDate())

    }
  }, [jobState.position_type])

  return (
    <>
      <div className="form-group py-2 ">
        {validation && jobState.position_type !== "temp" && errorMessage && (
          <p className="text-danger">
            Before Continuing you must select a Position, Practice, and Hiring Type.
          </p>
        )}
        {validation && jobState.position_type === "temp" && errorMessage && (
          <p className="text-danger">
            Before Continuing you must select a Position, Practice, and Hiring
            Type. Since you selected temp, you must fill out all temp fields as
            well.
          </p>
        )}
        <h4 className="font-weight-bold">Position</h4>
        <select
          className="form-select"
          id="job_type"
          name="job_type"
          onChange={e => updateJobState("job_type_id", e.target.value)}
          value={jobState.job_type_id}>
          <option value="" className="text-primary">Select a position</option>
          {job_types.map(position_type => (
            <option key={position_type.id} value={position_type.id} className="text-primary">
              {position_type.title}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group py-2">
        <h4 className="font-weight-bold">Location</h4>
        <select
          className="form-select"
          id="practice"
          onChange={e => updateJobState("practice_id", e.target.value)}
          value={jobState.practice_id}>
          <option value="" className="text-primary">Select a location</option>
          {practices.map(practice => (
            <option key={practice.id} value={practice.id} className="text-primary">
              {practice.name} ({practice.street_address}, {practice.city}, {practice.state}, {practice.zip})
            </option>
          ))}
        </select>
        <a className="button text-decoration-none" href="/practices">
          Add Another Location
        </a>
      </div>

      {jobState.position_type !== "temp" && (
        <div className="form-group py-2">
          <h4 className="font-weight-bold">
            Confidential?
          </h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={jobState.confidential}
              value=""
              id="flexCheckDefault"
              onChange={selectConfidential}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Keep Dental Practice Details Confidential
            </label>
          </div>
        </div>
      )}

      <div className="form-group py-2">
        <h4 className="font-weight-bold">Hiring Type</h4>
        <select
          className="form-select"
          id="position_type"
          onChange={e => updateJobState("position_type", e.target.value)}
          value={jobState.position_type}>
          <option value="" className="text-primary">
            Select a position type...
          </option>
          {position_types.map(position_type => (
            <option key={position_type.id} value={position_type.shortname} className="text-primary">
              {position_type.name}
            </option>
          ))}
        </select>
      </div>

      {jobState.position_type === "temp" && (
        <div className="form-group py-2 ">
          <h4 className="font-weight-bold">Mobile Number</h4>
          <p>You will receive text message updates for your temp job request.</p>
          <input
            className="form-control"
            id="sms_phone"
            name="sms_phone"
            type="text"
            placeholder="555-555-5555"
            onChange={e => updateJobState("sms_phone", e.target.value)}
            value={jobState.sms_phone}
          />
        </div>
      )}

      {jobState.position_type !== "temp" && (
        <div className="form-group py-2">
          <h4 className="font-weight-bold">Years of Experience</h4>
          <select
            className="form-select"
            id="years_experience"
            onChange={e => updateJobState("years_experience", e.target.value)}
            value={jobState.years_experience}>
            <option value="0-2" className="text-primary">0-2 Year</option>
            <option value="2-5" className="text-primary">2-5 Years</option>
            <option value="5+" className="text-primary">5+ Years</option>
          </select>
        </div>
      )}

      {jobState.position_type === "temp" && (
        <div className="form-group py-2 ">
          <h4 className="font-weight-bold">Temp Schedule</h4>
          <h5>Temp Date</h5>
          <DatePicker
            className="form-control"
            selected={jobState.temp_date}
            onChange={e => tempDateHandler(e)}
          />
          <div className="row">
            {" "}
            <div className="col-6">
              {" "}
              <h5>Start Time</h5>
              <Datetime
                value={moment.utc(jobState.temp_start)}
                inputProps={{ name: `job[temp_start]`, readOnly: true }}
                onChange={date => startTimeHandler(date)}
                dateFormat={false}
                timeFormat={true}
                timeConstraints={{
                  minutes: {
                    step: 15,
                    min: 0,
                    max: 59
                  }
                }}
              />
            </div>
            <div className="col-6">
              {" "}
              <h5>End Time</h5>
              <Datetime
                value={moment.utc(jobState.temp_end)}
                inputProps={{ name: `job[temp_end]`, readOnly: true }}
                onChange={date => endTimeHandler(date)}
                dateFormat={false}
                timeFormat={true}
                timeConstraints={{
                  minutes: {
                    step: 15,
                    min: 0,
                    max: 59
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="py-2">
        <button
          type="button"
          className="btn btn-dark pull-left"
          onClick={() => window.location.href = "/jobs/manage"}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary pull-right" onClick={() => validationGI()}>
          Continue
        </button>
      </div>
    </>
  )
}
