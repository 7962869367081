import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Form from "./Jobs/Form";
import "react-app-polyfill/ie9";
import moment from "moment";

interface Event {
  id: string;  // Changed from number to string
  title: string;
  start: string;
  end: string;
  url: string;
  description: string;
  color: string;
}

interface ScheduleProps {
  authenticity_token: string;
  practices: any[];
  all_skills: any[];
  skill_categories: any[];
  job_types: any[];
  form_elements: any[];
  position_types: any[];
  job_skills: any[];
  job: any;
}

const Schedule: React.FC<ScheduleProps> = ({
  authenticity_token,
  practices,
  all_skills,
  skill_categories,
  job_types,
  form_elements,
  position_types,
  job_skills,
  job
}) => {
  const [showForm, setShowForm] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [clickedDay, setClickedDay] = useState<string | null>(null);

  useEffect(() => {
    loadOpenEvents();
    loadClosedEvents();
    loadBookedEvents();
  }, []);

  const handleDayClick = (dateClickInfo: { dateStr: string }) => {
    setShowForm(true);
    setClickedDay(dateClickInfo.dateStr);
  };

  const cancelForm = () => {
    setShowForm(false);
  };

  const loadOpenEvents = () => {
    fetch("/temps/open.json")
      .then((res) => res.json())
      .then(
        (result) => {
          const newEvents = result.map((r: { temp: any }) => {
            const rec = r["temp"];
            return {
              id: String(rec["id"]),  // Convert to string
              title: "Open",
              start: moment(rec["temp_start"]).utc().format(),
              end: moment(rec["temp_end"]).utc().format(),
              url: rec["job_url"],
              description: "Open",
              color: rec["color"],
            };
          });
          setEvents(prev => [...prev, ...newEvents]);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const loadClosedEvents = () => {
    fetch("/temps/open.json?closed=1")
      .then((res) => res.json())
      .then(
        (result) => {
          const newEvents = result.map((r: { temp: any }) => {
            const rec = r["temp"];
            return {
              id: String(rec["id"]),  // Convert to string
              title: "Closed",
              start: moment(rec["temp_start"]).utc().format(),
              end: moment(rec["temp_end"]).utc().format(),
              url: rec["job_url"],
              description: "Open",
              color: rec["color"],
            };
          });
          setEvents(prev => [...prev, ...newEvents]);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const loadBookedEvents = () => {
    fetch("/temps/filled.json")
      .then((res) => res.json())
      .then(
        (result) => {
          const newEvents = result.map((r: { temp: any }) => {
            const rec = r["temp"];
            return {
              id: String(rec["id"]),  // Convert to string
              title: "Filled",
              start: moment(rec["temp_start"]).utc().format(),
              end: moment(rec["temp_end"]).utc().format(),
              url: rec["job_url"],
              description: rec["practice"],
              color: rec["color"],
            };
          });
          setEvents(prev => [...prev, ...newEvents]);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const eventRender = (eventInfo: any) => {
    const { event, el } = eventInfo;
    let opacity = "0.7";

    if (event.title === "Closed" || event.title === "Filled") {
      opacity = "1.0";
    }

    el.style.backgroundColor = event.backgroundColor || event.color;
    el.style.opacity = opacity;
  };

  console.log("Events: ", events);

  return (
    <React.Fragment>
      {showForm ? (
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-8 offset-md-2 my-5 p-0"
              id="jobformcontainer"
            >
              <Form
                authenticity_token={authenticity_token}
                practices={practices}
                skills={all_skills}
                skill_categories={skill_categories}
                job_types={job_types}
                form_elements={form_elements}
                position_types={position_types}
                job_skills={job_skills}
                job={{
                  ...job,
                  position_type: "temp",
                  temp_date: moment(clickedDay).format("YYYY-MM-DD"),
                }}
                cancelButton={cancelForm}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            dateClick={handleDayClick}
            events={events}
            eventDidMount={eventRender}
            timeZone="UTC"
            displayEventTime={true}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short'
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Schedule;
