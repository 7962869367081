import React from "react"
import JobTags from "./JobTags"
import DOMPurify from "dompurify";
// import moment from 'moment';

const Job = ({ job, noTags }) => {

  // urgent if spotlight or priority is true
  const urgent = job.spotlight || job.priority

  return (
    <div className={`field ${job.spotlight ? 'spotlight' : ''}`}
      style={{ cursor: 'pointer' }}
      onClick={() => window.open(job.url, "_blank")}>
      <li className="col-12 d-flex justify-content-between border-top border-start border-end py-2 pt-1 pb-3 border-radius-1">
        <div className="col-2 p-3" style={{ minHeight: 40 }}>
          <img src={DOMPurify.sanitize(job.image)} alt="" className="img-fluid rounded-circle" />
        </div>

        <div className="col-10 d-flex flex-column justify-content-center">
          <div className="job-list-content">
            {urgent && (
              <span className="urgent-tag rounded px-2 py-1 me-1 pull-right" style={{ color: '#cc0066' }}>
                <i className="fa fa-clock"></i> Urgent
              </span>
            )}
            <div className="d-flex flex-row align-items-center pe-2">
              <h4 className="mb-0 mt-2 col-6 col-xl-8">
                {job.title}
              </h4>
            </div>
            <div className="d-flex my-2 flex-wrap">
              <span className="profile-attribute-bubble rounded px-2 py-1 me-1">
                <i className="fa fa-briefcase"></i> {job.practice_name}
              </span>
              <span className="profile-attribute-bubble rounded px-2 py-1 me-1">
                <i className="fa fa-map-marker"></i> {job.practice_city},{" "}
                {job.practice_state}
              </span>
              {job.compensation && (
                <span className="profile-attribute-bubble rounded px-2 py-1 me-1">
                  <i className="fa fa-money"></i> {job.compensation}
                </span>
              )}
            </div>
            {!noTags && <JobTags job={job} />}
          </div>
          {noTags && (
            <a href="https://www.ziprecruiter.com/jobs" id="jobs_widget_link" className="text-decoration-none">
              <span>Job Search by</span>{" "}
              <span id="zr_logo_container">
                <img
                  id="zr_logo"
                  src="https://www.ziprecruiter.com/img/logos/logo-sm-black-304px.png"
                  alt="ZipRecruiter"
                  height={30}
                />
              </span>
            </a>
          )}
        </div>


      </li>
      <div className="clearfix"></div>
    </div >
  )
}

export default Job
