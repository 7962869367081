import React, { useEffect } from "react";
import { LexicalRichTextareaEditor } from "./LexicalRichTextareaEditor";

interface ExperienceItem {
  employer: string;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  current: boolean;
}

interface ProfileExperienceProps {
  experience: ExperienceItem[];
  update: (experience: ExperienceItem[]) => void;
}

// Default empty experience item
const BlankExperienceItem: ExperienceItem = {
  employer: "",
  title: "",
  description: "",
  start_date: "",
  end_date: "",
  current: false,
};

const ProfileExperience = (props: ProfileExperienceProps) => {
  const [expandedIds, setExpandedIds] = React.useState<number[]>([0]);
  const newItemRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState({
    experience: props.experience && props.experience.length > 0
      ? props.experience
      : [BlankExperienceItem]
  });

  // Propagate changes to parent component
  useEffect(() => {
    props.update(state.experience);
  }, [state.experience]);

  const addExperience = () => {
    const newExperience = [...state.experience, BlankExperienceItem];
    setState({ experience: newExperience });

    // Expand the new item
    setExpandedIds((prev) => [...prev, state.experience.length]);

    // Scroll to new item
    setTimeout(() => {
      newItemRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const updateItem = (key: string, value: any, index: number) => {
    const experiences = [...state.experience];
    experiences[index] = {
      ...experiences[index],
      [key]: value
    };
    setState({ experience: experiences });
  };

  // Toggle accordion expansion
  const toggleAccordion = (index: number) => {
    setExpandedIds((prev) =>
      prev.includes(index) ? prev.filter((id) => id !== index) : [...prev, index]
    );
  };

  // Delete experience item
  const deleteItem = (index: number) => {
    if (state.experience.length <= 1) return;

    if (window.confirm("Are you sure you want to delete this experience?")) {
      const experiences = [...state.experience];
      experiences.splice(index, 1);
      setState({ experience: experiences });

      // Remove from expanded IDs if needed
      setExpandedIds((prev) => prev.filter((id) => id !== index));
    }
  };

  // Move item up/down in the list
  const moveUpFun = (i: number) => {
    if (i <= 0) return false;
    const experience = move(state.experience, i, i - 1);
    setState({ experience });
  };

  const moveDownFun = (i: number) => {
    if (i === state.experience.length - 1) return false;
    const experience = move(state.experience, i, i + 1);
    setState({ experience });
  };

  return (
    <div className="accordion" id="experienceAccordion">
      {state.experience.map((exp, index) => (
        <div
          className="accordion-item mb-3 border"
          key={`exp-${index}`}
          ref={index === state.experience.length - 1 ? newItemRef : null}
        >
          <h2 className="accordion-header d-flex justify-content-between align-items-center">
            <button
              className={`accordion-button ${!expandedIds.includes(index) ? 'collapsed' : ''} bg-light text-dark`}
              type="button"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: 'pointer', flexGrow: 1 }}
            >
              {exp.title || 'New Experience'} at {exp.employer || 'New Employer'}
            </button>
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-sm btn-light"
                onClick={(e) => {
                  e.stopPropagation();
                  moveUpFun(index);
                }}
                title="Move Up"
              >
                <i className="fa fa-arrow-up"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-light mx-1"
                onClick={(e) => {
                  e.stopPropagation();
                  moveDownFun(index);
                }}
                title="Move Down"
              >
                <i className="fa fa-arrow-down"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-light"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteItem(index);
                }}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </h2>

          <div className={`accordion-collapse collapse ${expandedIds.includes(index) ? 'show' : ''}`}>
            <div className="accordion-body border-top">
              <Experience
                employer={exp.employer}
                title={exp.title}
                start_date={exp.start_date}
                end_date={exp.end_date}
                description={exp.description}
                current={exp.current}
                updateEmployer={(value) => updateItem("employer", value, index)}
                updateTitle={(value) => updateItem("title", value, index)}
                updateStartDate={(value) => updateItem("start_date", value, index)}
                updateEndDate={(value) => updateItem("end_date", value, index)}
                updateDescription={(value) => updateItem("description", value, index)}
                toggleCurrent={() => updateItem("current", !exp.current, index)}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="text-center mt-3">
        <button
          onClick={addExperience}
          className="btn btn-outline-primary"
        >
          <i className="fa fa-plus-circle mr-2"></i> Add Experience
        </button>
      </div>
    </div>
  );
};

interface ExperienceProps {
  employer: string;
  title: string;
  start_date: string;
  end_date: string;
  description: string;
  current: boolean;
  updateEmployer: (value: string) => void;
  updateTitle: (value: string) => void;
  updateStartDate: (value: string) => void;
  updateEndDate: (value: string) => void;
  updateDescription: (value: string) => void;
  toggleCurrent: () => void;
}

const Experience = ({
  employer,
  title,
  start_date,
  end_date,
  description,
  current,
  updateEmployer,
  updateTitle,
  updateStartDate,
  updateEndDate,
  updateDescription,
  toggleCurrent,
}: ExperienceProps) => {
  // Generate a unique key for the editor to prevent state issues
  const editorKey = React.useMemo(() => `experience-${Date.now()}`, []);

  return (
    <div className="mt-2">
      <div className="form-group mb-3">
        <label htmlFor="employer" className="form-label">Employer</label>
        <input
          type="text"
          className="form-control"
          id="employer"
          name="employer"
          value={employer || ''}
          onChange={(e) => updateEmployer(e.target.value)}
        />
      </div>

      <div className="form-group mb-3">
        <label htmlFor="title" className="form-label">Title</label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={title || ''}
          onChange={(e) => updateTitle(e.target.value)}
        />
      </div>

      <div className="row">
        <div className="col-md-5 mb-3">
          <label htmlFor="start_date" className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            id="start_date"
            name="start_date"
            value={start_date || ''}
            onChange={(e) => updateStartDate(e.target.value)}
          />
        </div>

        <div className="col-md-5 mb-3">
          <label htmlFor="end_date" className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            id="end_date"
            name="end_date"
            value={end_date || ''}
            onChange={(e) => updateEndDate(e.target.value)}
            disabled={current}
          />
        </div>

        <div className="col-md-2 d-flex align-items-center mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input mt-1"
              id="current"
              name="current"
              checked={current || false}
              onChange={toggleCurrent}
            />
            <label className="form-check-label" htmlFor="current">
              Current
            </label>
          </div>
        </div>
      </div>

      <div className="form-group mb-3 text-start">
        <label htmlFor="description" className="form-label">Description</label>
        <LexicalRichTextareaEditor
          key={editorKey}
          editorState={description || ''}
          onChangeDescription={updateDescription}
        />
      </div>
    </div>
  );
};

// Helper function to move items within an array
function move(arr: ExperienceItem[], old_index: number, new_index: number): ExperienceItem[] {
  if (old_index < 0 || old_index >= arr.length || new_index < 0 || new_index >= arr.length) {
    return arr;
  }

  const newArray = [...arr];
  const element = newArray[old_index];
  newArray.splice(old_index, 1);
  newArray.splice(new_index, 0, element);
  return newArray;
}

export default ProfileExperience;
