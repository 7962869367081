import * as React from 'react';

export default function PlanDescription({ planType, essentialImage, staffingImage, staffingPlan, boostedPlan }: { planType: string, essentialImage: string, staffingImage: string, staffingPlan: string, boostedPlan: string, }) {

  // Check if planType includes string "Essential"
  const isEssential = planType.includes('Essential');
  const isStaffing = planType.includes('Boosted');
  const isFullStaffing = planType.includes('Full');
  const isPriority = planType.includes('Priority');

  console.log(staffingPlan, boostedPlan)

  return (
    <>
      {isEssential &&
        <EssentialPlanDescription essentialImage={essentialImage} />
      }
      {isStaffing &&
        <StaffingPlanDescription staffingImage={staffingImage} staffingPlan={staffingPlan} />
      }
      {isFullStaffing &&
        <FullStaffingPlanDescription staffingImage={staffingImage} />
      }
      {isPriority &&
        <PriorityPlanDescription staffingImage={staffingImage} boostedPlan={boostedPlan} staffingPlan={staffingPlan} />
      }
    </>
  )
}

function EssentialPlanDescription({ essentialImage }) {
  return <>
    <ul className="list-group-flush">
      <li className="list-group-item pb-1">

        <div className="col-3 mb-3">
          <img
            className="img-fluid d-none d-xl-block"
            src={essentialImage}
          />
        </div>
        {/* Align items vertically center */}
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">What you get with an <u>Essential Membership</u></span>
          <p>
            The Essential membership is On Demand and designed to get you
            qualified full-time, part-time, or temporary dental staff
            quickly. A membership gives you 24/7 access to our rapidly
            growing network of 1,000,000+ dental professionals open to temporary
            and permanent work.
          </p>
        </div>


      </li>
      <li className="list-group-item">
        • View resumes and profiles online.
      </li>
      <li className="list-group-item">
        • Invite a candidate to interview via direct message.
      </li>
      <li className="list-group-item">
        • Book available dental temps using your smartphone.
      </li>
      <li className="list-group-item">
        • Post unlimited job openings.
      </li>
      <li className="list-group-item">
        • Save big. No daily fees, no hefty percentages, and no
        cost to hire a temp permanently.
      </li>
    </ul>
  </>
}

function StaffingPlanDescription({ staffingImage, staffingPlan }) {
  return <>
    <ul className="list-group-flush">
      <li className="list-group-item pb-1">

        <div className="col-3 mb-3">
          <img
            className="img-fluid d-none d-xl-block"
            src={staffingImage}
          />
        </div>
        {/* Align items vertically center */}
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">What you get with a <u>Boosted Plan</u></span>
          <p>For a one time fee of $599, we bring you candidates and you take it from there.</p>
        </div>
      </li>
      <ul className="list-group-flush">
        <li className="list-group-item">
          &#8226; Dedicated Recruiter to bring you qualified candidates for 30 days.
        </li>
        <li className="list-group-item">
          &#8226; 100x more visibility than a standard posting.
        </li>
        <li className="list-group-item">
          &#8226; More applicants for your job.
        </li>
        <li className="list-group-item">
          &#8226; An expertly-crafted job description to attract the best
          talent.
        </li>
        <li className="list-group-item">
          &#8226; Featured employer profile so candidates are attracted to
          your office.
        </li>
      </ul>
      <li className="list-group-item pb-1">
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">
            Want Even More Candidates?
          </span>
          <p>Our Full Staffing Service offers concierge-level service that includes even more candidates, candidate screening, a dedicated recruiter, and more.</p>
        </div>
        <div className="col-12 d-flex flex-row justify-content-between">
          <a href={`/plans/${staffingPlan}`} className="text-decoration-none">View Full Service Staffing Plan</a>
        </div>
      </li>
    </ul>
  </>
}

function FullStaffingPlanDescription({ staffingImage }) {
  return <>
    <ul className="list-group-flush">
      <li className="list-group-item pb-1">

        <div className="col-3 mb-3">
          <img
            className="img-fluid d-none d-xl-block"
            src={staffingImage}
          />
        </div>
        {/* Align items vertically center */}
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">What you get with <u>Full Service Staffing</u></span>
          <p>This is concierge-level service so you won't lift a finger beyond
            giving us a call. Everything above is included for 30 days for a
            one time payment of $1299.</p>
        </div>
      </li>
      <ul className="list-group-flush">
        <li className="list-group-item">
          &#8226; Dedicated Recruiter to bring you qualified candidates for 30 days.
        </li>
        <li className="list-group-item">
          &#8226; 100x more visibility than a standard posting.
        </li>
        <li className="list-group-item">
          &#8226; More applicants for your job.
        </li>
        <li className="list-group-item">
          &#8226; An expertly-crafted job description to attract the best
          talent.
        </li>
        <li className="list-group-item">
          &#8226; Featured employer profile so candidates are attracted to
          your office.
        </li>
        <li className="list-group-item fw-bold">
          &#8226; Candidate screening & License verification.
        </li>
        <li className="list-group-item fw-bold">
          &#8226; Assist with finding temp staff as needed.
        </li>
      </ul>
    </ul>
  </>
}
function PriorityPlanDescription({ staffingImage, boostedPlan, staffingPlan }) {
  return <>
    <ul className="list-group-flush">
      <li className="list-group-item pb-1">

        <div className="col-3 mb-3">
          <img
            className="img-fluid d-none d-xl-block"
            src={staffingImage}
          />
        </div>
        {/* Align items vertically center */}
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">What you get with a <u>Priority Job</u></span>
          <p>Need to hire faster? A priority job can help find you candidates faster by jumping to the front of the line with more promotion than a standard job posting.</p>
        </div>
      </li>
      <ul className="list-group-flush">
        <li className="list-group-item">
          &#8226; Prioritized Job Posting for 10 Days
        </li>
        <li className="list-group-item">
          &#8226; 10x more visibility than a standard posting.
        </li>
        <li className="list-group-item">
          &#8226; Jobs are cross-promoted on social media.
        </li>
      </ul>
      <li className="list-group-item pb-1">
        <div className="col-12 d-flex flex-column justify-content-center">
          <span id="essListHead" className="pb-2">
            Want Even More Candidates?
          </span>
          <p>Our Staffing Services offer a higher level of service that includes more candidates, candidate screening, a dedicated recruiter, and more.</p>
        </div>
        <div className="col-12 d-flex flex-column justify-content-between">
          <a href={`/plans/${boostedPlan}`} className="text-decoration-none">View Boosted Plan</a>
          <a href={`/plans/${staffingPlan}`} className="text-decoration-none">View Full Service Staffing Plan</a>
        </div>
      </li>
    </ul>

  </>
}