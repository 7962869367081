import './Common-poly'
import React from "react"
// TODO: Replace with lexical
import RichTextEditor from 'react-rte';

const JobDescription = ({ description }) => {
    const mode = description.includes('<') ? 'html' : 'markdown';
    const text = RichTextEditor.createValueFromString(description, mode);

    return (
        <div className="content-description">
            <h2 className="margin-bottom-10">Job Description</h2>
            <p dangerouslySetInnerHTML={{ __html: text.toString('html') }}></p>
        </div>
    );
}

export default JobDescription;