import * as React from "react"
import JobForm from "./JobFormContext"
import Select from "react-select"

export default function Skills({
  props,
  tabIndexFunc,
  form_elements,
  skill_categories,
  skills,
}) {
  const [jobState, setJobState] = React.useContext(JobForm)
  //this state is just a copy of the job_skills prop

  const [skillState, setSkillState] = React.useState(jobState.skill_ids)

  // useEffect, if skillState doesnt include 55, remove any skills with category "Language Proficiency (other than English)"
  React.useEffect(() => {
    const cSkills = skills.filter(skill => skill.category === "Language Proficiency (other than English)")
    // Check if skillState includes any of cSkills.id
    if (!skillState.includes(55) && skillState.some(id => cSkills.map(skill => skill.id).includes(id))) {
      // If yes, remove them
      setSkillState(skillState.filter(id => !cSkills.map(skill => skill.id).includes(id)))
    }
  }, [skillState])


  React.useEffect(
    () => setJobState({ ...jobState, skill_ids: skillState }),
    [skillState]
  )

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any"
  }

  return (
    <>
      <h4>Skills</h4>
      {/* // Bootstrap list type none */}
      <div>
        {skill_categories.map(category => {
          const category_skills = skills.filter(skill => skill.category === category)

          // Skip if category.name == "Language Proficiency (other than English)" & skill with id of 55 is not in job_skills
          const category_lang = category === "Language Proficiency (other than English)" &&
            !skillState.includes(55)

          if (category_lang) {
            return null
          }

          const select_skills = category_skills.sort((a, b) => a.name.localeCompare(b.name))
            .map(skill => ({
              value: skill.id,
              label: skill.name,
              color: skill.color
            }))

          const customStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
              }
            },
            multiValueLabel: (styles, { data }) => {
              return {
                // ...styles,
                backgroundColor: data.color,
                color: 'white',
                borderRadius: '5px 0 0 5px',
                paddingLeft: 8,
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 3,
              }
            },
            multiValueRemove: (styles, { data }) => {
              // Unset styles.borderRadius
              return {
                ...styles,
                backgroundColor: data.color,
                color: 'white',
                borderRadius: '0 5px 5px 0',
                paddingRight: 5,
                paddingLeft: 4,
                marginLeft: -1,
              }
            },
          }

          return (
            <div className="mb-4">
              <h6 className=" fw-bold">{category}</h6>

              <Select
                styles={customStyles}
                options={select_skills}
                isMulti
                value={select_skills.filter(skill => skillState.includes(skill.value))}
                onChange={selected => {
                  // Order category_skills by category
                  // Get selected_skills that match selected
                  const selected_skills = category_skills.filter(skill => selected.map(s => s.value).includes(skill.id))

                  // Get category_skills that dont match selected skills
                  const unselected_skills = category_skills.filter(skill => !selected.map(s => s.value).includes(skill.id))

                  // Remove Duplicate skills from selected_skills
                  setSkillState(sS => ([
                    ...sS,
                    ...selected_skills.map(s => s.id).filter(s => !sS.includes(s))
                  ]))

                  // Remove unselected skills from skillState
                  setSkillState(sS => sS.filter(s => !unselected_skills.map(u => u.id).includes(s)))

                }}
              />
            </div>
          )
        })}
      </div>

      <button
        type="button"
        className="btn btn-primary pull-left"
        onClick={() => {
          tabIndexFunc(0)
        }}
      >
        Back
      </button>
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={() => {
          tabIndexFunc(2)
        }}
      >
        Continue
      </button>
    </>
  )
}