import * as React from 'react'
import Dropzone from "dropzone"
import { useEffect, useRef } from 'react'

import { ProfileFormContext } from '../ProfileEditForm'
import { ACTIONS } from './ProfileFormReducer'

// Submit ProfileResumeDeleteField
const submitProfileResumeDeleteField = (profile, dispatch, authenticity_token, setFiles) => {
  const profileData = {
    delete_resume: '1',
    profile: {
      delete_resume: '1',
    }
  }

  const url = `/profiles/${profile.id}.json`
  const method = "PATCH"
  const headers = {
    "X-CSRF-Token": authenticity_token,
    "Content-Type": "application/json",
  }

  fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(profileData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      // throw new Error("Network response was not ok.")
    })
    .then((response) => {
      // Dispatch SET_FIELD resume to null
      dispatch({
        type: ACTIONS.SET_FIELD,
        payload: { resume_file_name: null },
      })
      setFiles([1])
    })
    .catch((error) => console.error("Error:", error))
}


export default function ResumeUpload() {
  const dropzoneRef = useRef(null);
  const myDropzone = useRef(null);

  // Context
  const [state, dispatch] = React.useContext(ProfileFormContext)

  // Files State
  const [files, setFiles] = React.useState([])

  // Get Files from uploaded_profile_documents.json
  const getFiles = () => {
    // fetch('/uploaded_profile_documents.json')
    //   .then(response => response.json())
    //   .then(data => {
    //     setFiles(data)

    //     // emit
    //     console.log("Data", data);
    //     // Emit file added for each data
    //     data.forEach((file) => {
    //       // Mockfile
    //       const mockFile = {
    //         id: file.id,
    //         name: file.document_file_name,
    //         size: file.document_file_size,
    //         url: file.url,
    //         thumbnail: "/doc.png"
    //       }

    //       myDropzone.current.emit("addedfile", mockFile);
    //       myDropzone.current.emit("complete", mockFile);
    //       myDropzone.current.emit("thumbnail", mockFile, "/doc.png");
    //     })
    //   })
  }

  useEffect(() => {
    if (!myDropzone.current) {
      myDropzone.current = new Dropzone(dropzoneRef.current, {
        url: "/profiles/" + state.profile.id + "/upload_resume",
      });


      // Options.iploadProfileDocuments headers
      myDropzone.current.options.headers = {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }

      // dictDefaultMessage
      myDropzone.current.options.dictDefaultMessage = "Drag and drop your documents here or click to upload"

      // addRemoveLinks
      myDropzone.current.options.addRemoveLinks = false

      // dictRemoveFileConfirmation
      myDropzone.current.options.dictRemoveFileConfirmation = "Are you sure you want to remove this file?"

      // createImageThumbnails
      myDropzone.current.options.createImageThumbnails = false

      // Preview Template with image
      myDropzone.current.options.previewTemplate = `
        <div class="dz-preview dz-file-preview bg-secondary rounded">
          <div class="dz-details row my-3">
            <div class="col-2 p-2 flex-column d-flex justify-content-center align-items-center">
              <img data-dz-thumbnail="" class="img-fluid" src="/doc.png">
            </div>
            <div class="col-8 flex-column d-flex justify-content-center">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size" data-dz-size></div>
              </div>
              <div class="col-2 flex-column d-flex justify-content-center align-items-center">
                <div class="dz-remove">
                  <a class="btn btn-danger btn-sm" data-dz-remove>
                    <i class="fas fa-trash"></i>
                  </a>
                </div>
              </div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          </div>
          `
      // <div class="dz-success-mark"><span>✔</span></div>
      // <div class="dz-error-mark"><span>✘</span></div>
      // <div class="dz-error-message"><span data-dz-errormessage></span></div>

      getFiles();
    }

    myDropzone.current.on("addedfile", (file) => {
      console.log("Added", file)

      setFiles([...files, file])
    });

    myDropzone.current.on("removedfile", (file) => {
      submitProfileResumeDeleteField(state.profile, dispatch, state.authenticity_token, setFiles)

      console.log("Removed", file)
    });

    myDropzone.current.on("success", (file) => {
      console.log("Success", file)
    });

    myDropzone.current.on("error", (file) => {
      console.log("Error", file)
    });

    myDropzone.current.on("complete", (file) => {
      console.log("Complete", file)
    });

    myDropzone.current.on("sending", (file) => {
      console.log("Sending", file)
    });

    myDropzone.current.on("canceled", (file) => {
      console.log("Canceled", file)
    })


  }, []);


  React.useEffect(() => {
    if (state.profile.resume_file_name == null) {
      setFiles([])
    } else {
      const mockFile = {
        id: "1",
        name: "Resume",
        size: "123",
        url: "",
        thumbnail: "/doc.png"
      }

      myDropzone.current.emit("addedfile", mockFile);
      myDropzone.current.emit("complete", mockFile);
      myDropzone.current.emit("thumbnail", mockFile, "/doc.png");
      setFiles([mockFile])
    }
  }, [state.profile.resume_file_name])

  return (
    <>
      <div id="DocumentDropzone" className="DocumentDropzone container my-3">
        <label htmlFor="DocumentDropzone" className="form-label">Resume</label>
        <div id="DocumentDropzoneContainer" ref={dropzoneRef}>
          {/* Drag to upload message */}
          {files.length == 0 && (
            <div className="text-center bg-light pt-5 px-2 pb-4 rounded border"
              onClick={() => myDropzone.current.hiddenFileInput.click()}
              style={{ cursor: "pointer" }}
            >
              <p><i className="fas fa-cloud-upload-alt"></i> Drag and drop your documents here or click to upload</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}