import * as React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Profile } from "../types/Profile";

interface ProfileField {
  field: any;
  value: number;
  name: string;
  section: string;
}

function check_fields(profile: Profile): ProfileField[] {
  return [
    { field: profile.first_name, value: 5, name: "First Name", section: "1" },
    { field: profile.last_name, value: 5, name: "Last Name", section: "1" },
    // ...existing fields array...
  ];
}

interface ProfileCompletenessProps {
  profile: Profile;
}

export function ProfileCompleteness({ profile }: ProfileCompletenessProps) {
  const profileFields = check_fields(profile);
  const percentage =
    100 -
    profileFields.reduce((acc, field) => {
      if (Array.isArray(field.field) ? field.field.length === 0 : !field.field) {
        return acc + field.value;
      }
      return acc;
    }, 0);

  return (
    <div className="col-lg-12 my-5 contact-font bg-secondary rounded p-4">
      <div className="info-banner">
        <div className="info-content">
          <h2>Profile Completeness - {percentage}%</h2>
          <ProgressBar now={percentage} />
        </div>
        <p>A more complete profile will help you get more job offers.</p>
      </div>
    </div>
  );
}
