import * as React from "react";
import { LexicalRichTextareaEditor } from "../FormComponents/LexicalRichTextareaEditor";

type LocationDescriptionProps = {
  initialDescription?: string;
  fieldName?: string;
}

export default function LocationDescription({
  initialDescription = '',
  fieldName = "practice[description]"
}: LocationDescriptionProps) {
  const [htmlContent, setHtmlContent] = React.useState(initialDescription);

  const handleEditorChange = (newHtmlContent: string) => {
    setHtmlContent(newHtmlContent);
  };

  React.useEffect(() => {
    console.log('HTML Content Updated:', htmlContent);
  }, [htmlContent]);

  return (
    <div className="location-description">
      <LexicalRichTextareaEditor
        editorState={htmlContent}
        onChangeDescription={handleEditorChange}
      />
      <input type="hidden" name={fieldName} value={htmlContent} />
    </div>
  );
}