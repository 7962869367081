import * as React from "react";
import moment from "moment-timezone";

const Application = ({ application, authenticity_token }) => {
  const { job } = application;
  const timezone = job.practice?.timezone || "America/Los_Angeles"; // Default timezone if not available
  const application_date = moment(application.created_at)
    .tz(timezone)
    .format("llll");
  const updated_date = moment(application.updated_at)
    .tz(timezone)
    .format("llll");

  return (
    <div className="ManageApplicantContainer">
      <div
        className={`application ${application.withdrawn ? "withdrawn" : ""}`}
        style={{ flex: 1 }}
      >
        <div className="app-content">
          <div className="info">
            <a href={`/jobs/${encodeURIComponent(job.id)}`}>
              <img src={encodeURI(job.practice?.avatar_url || '/default-practice-avatar.png')} alt="" />
            </a>
            <span>
              <a href={`/jobs/${encodeURIComponent(job.id)}`}>
                {job.job_type?.title}
              </a>
            </span>
            <ul>
              <li>
                <i className="fa fa-briefcase"></i> {job.practice_name || job.practice?.name}
              </li>
              <li>
                <i className="fa fa-map-marker"></i> {job.practice?.city}, {job.practice?.state}
              </li>
              {job.compensation_full && (
                <li>
                  <i className="fa fa-money"></i> {job.compensation_full}
                </li>
              )}
              <li>
                <i className="fa fa-calendar"></i> Applied {application_date}
              </li>
              {application.withdrawn && (
                <li>
                  <i className="fa fa-calendar"></i> Withdrawn {updated_date}
                </li>
              )}
            </ul>
          </div>
          {!application.withdrawn && (
            <a
              target="_blank"
              href={`/job_applications/${encodeURIComponent(application.id)}/withdraw`}
              className="button gray"
              style={{ color: "red", height: "26px" }}
            >
              Withdraw Application
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const Applications = ({ applications, authenticity_token, timezone }) => {
  return applications.map((application) => {
    return Application({
      application,
      authenticity_token,
      timezone,
    });
  });
};

export default Applications;
