import * as React from "react";
import * as CSS from "csstype";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  zip: string;
  image: string;
  url: string;
  compensation: string | number;
  job_types: Array<any>;
  skills: Array<any>;
  position_types: Array<any>;
}

interface Job {
  [prop: string]: any;
}

interface InviteProfileProps {
  profile: Profile;
  job: Job;
  requested: Array<number>;
  accepted: Array<number>;
  declined: Array<number>;
  authenticity_token: string;
  callback?: (id: number) => any;
  requestingAll?: boolean;
}

const InviteProfile = ({ profile, job, authenticity_token, callback = undefined, requested = [], accepted = [], declined = [], requestingAll = false }: InviteProfileProps) => {
  const [state, setState] = React.useState({
    loading: false,
    requested: requested.indexOf(profile.id) >= 0,
    accepted: accepted.indexOf(profile.id) >= 0,
    declined: declined.indexOf(profile.id) >= 0
  });

  React.useEffect(() => {
    setState({
      ...state,
      requested: requested.indexOf(profile.id) >= 0,
      accepted: accepted.indexOf(profile.id) >= 0,
      declined: declined.indexOf(profile.id) >= 0
    });
  }, [requested, accepted, declined]);

  const inviteCandidate = () => {
    setState({ ...state, loading: true });

    const url = job.position_type == 'temp'
      ? `/jobs/${job.id}/request.json`
      : `/jobs/${job.id}/invite.json`;

    const options = {
      method: 'POST',
      redirect: 'manual',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticity_token
      },
      body: job.position_type == 'temp'
        ? JSON.stringify({ temp_requests: [profile.id] })
        : JSON.stringify({ invitees: [profile.id] })
    };

    fetch(url, options)
      .then(result => result.json())
      .then(
        result => {
          setState({ ...state, loading: false, requested: true });
          if (callback) callback(profile.id);
        },
        error => {
          setState({ ...state, loading: false });
          console.log(error);
        }
      );
  };

  if (state.loading || requestingAll) {
    return (
      <span className="status-badge status-loading btn-dd-small">
        <i className="fad fa-spinner fa-spin"></i> {job.position_type == 'temp' ? "Requesting..." : "Inviting..."}
      </span>
    );
  }

  if (state.requested || state.accepted) {
    return (
      <span className="status-badge status-success btn-dd-small">
        <i className="fad fa-check"></i> {job.position_type == 'temp' ? "Requested" : "Invited"}
      </span>
    );
  }

  if (state.declined) {
    return (
      <span className="status-badge status-declined btn-dd-small">
        <i className="fad fa-times"></i> {job.position_type == 'temp' ? "Declined" : "Declined"}
      </span>
    );
  }

  return (
    <a
      className="button btn-dd-small text-decoration-none"
      onClick={e => {
        e.stopPropagation();
        inviteCandidate();
      }}
    >
      <i className="fad fa-user-plus"></i> {job.position_type == 'temp' ? "Request Temp" : "Send Invite"}
    </a>
  );
};

export default InviteProfile;

const buttonStyle: CSS.Properties = {
  textAlign: "center",
  margin: "12px",
};

const spinnerStyle: CSS.Properties = {
  paddingRight: "15px",
  paddingTop: "10px",
};

const requestedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "#fff",
  color: "black",
  border: "none",
};

const acceptedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "white",
  color: "green",
  border: "none",
};

const declinedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "white",
  color: "#a00606",
  border: "none",
};
