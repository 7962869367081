import * as React from "react"
import JobTags from "./JobTags"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ReactCardFlip from 'react-card-flip';

export default function JobSpotlight({ jobs }) {
  return (
    <div className="container mt-4 mb-4 JobSpotlight px-0">
      <div className="row">
        <div className="d-md-none">
          <OwlCarousel className='owl-theme' loop margin={0} nav items={1} autoplay={true} autoplaySpeed={2000} autoHeightClass="test" callbacks={true}>
            {jobs.map(job => <Job job={job} key={job.id} />)}
          </OwlCarousel>
        </div>
        <div className="d-none d-xl-block">
          <OwlCarousel className='owl-theme' loop margin={0} nav items={4} autoplay={true} autoplaySpeed={2000} autoHeightClass="test" callbacks={true}>
            {jobs.map(job => <Job job={job} key={job.id} />)}
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}
function Job({ job }) {
  const backgroundStyle = { backgroundImage: `url('${job.image}')` }
  const onClickFunction = (url) => window.open(url, "_blank")

  return (
    <div className="col-12 my-3 d-flex align-items-stretch owl-job" style={{ paddingLeft: 10, paddingRight: 10 }}>
      {/* Icon Background Container */}
      <div className="col-12 d-flex justify-content-between border-top border-start border-end shadow-sm bg-icon align-items-stretch" style={backgroundStyle} onClick={() => onClickFunction(job.url)}>
        {/* Job Container */}
        <div className="py-2 pt-1 pb-3 bg-icon-inside d-flex  align-items-stretch col-12">
          <div className="col-12 d-flex flex-column">
            <div className="job-list-content d-flex flex-column flex-fill">
              {/* Job Title */}
              <div className="d-flex flex-row align-items-center py-3">
                <h4 className="ps-3 mb-0 mt-1">{job.title}</h4>
              </div>

              {/* Job Info Tags */}
              <div className="d-flex mb-2 mx-3 flex-wrap-off flex-column my-auto">
                <JobInfoTag className="fa fa-briefcase" text={job.practice_name} />
                <JobInfoTag className="fa fa-map-marker" text={`${job.practice_city}, ${job.practice_state}`} />
                {job.compensation && (<JobInfoTag className="fa fa-money" text={job.compensation} />)}
              </div>

              {/* Job Skills */}
              <div className="mx-3">
                <JobTags job={job} />
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div >
    </div>
  )
}


function FlippableJob({ job }) {
  const backgroundStyle = { backgroundImage: `url('${job.image}')` }
  const onClickFunction = () => window.open(job.url, "_blank")
  const [isFlipped, setIsFlipped] = React.useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(e);
  }

  return (
    <div onMouseEnter={() => setIsFlipped(true)} onMouseLeave={() => setIsFlipped(false)}>
      <ReactCardFlip isFlipped={isFlipped}>
        <div className="col-12 my-3 d-flex align-items-stretch owl-job" style={{ paddingLeft: 10, paddingRight: 10 }}>
          {/* Icon Background Container */}
          <div className="col-12 d-flex justify-content-between border-top border-start border-end shadow-sm bg-icon align-items-stretch" style={backgroundStyle} onClick={onClickFunction}>
            {/* Job Container */}
            <div className="py-2 pt-1 pb-3 bg-icon-inside d-flex  align-items-stretch col-12">
              <div className="col-12 d-flex flex-column">
                <div className="job-list-content d-flex flex-column flex-fill">
                  {/* Job Title */}
                  <div className="d-flex flex-row align-items-center py-3">
                    <h4 className="ps-3 mb-0 mt-1">{job.title}</h4>
                  </div>

                  {/* Job Info Tags */}
                  <div className="d-flex mb-2 mx-3 flex-wrap-off flex-column my-auto">
                    <JobInfoTag className="fa fa-briefcase" text={job.practice_name} />
                    <JobInfoTag className="fa fa-map-marker" text={`${job.practice_city}, ${job.practice_state}`} />
                    {job.compensation && (<JobInfoTag className="fa fa-money" text={job.compensation} />)}
                  </div>

                  {/* Job Skills */}
                  <div className="mx-3">
                    <JobTags job={job} />
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div >
        </div>
        <div className="col-12 my-3 d-flex align-items-stretch owl-job" style={{ paddingLeft: 10, paddingRight: 10 }}>
          {/* Icon Background Container */}
          <div className="col-12 d-flex justify-content-between border-top border-start border-end shadow-sm bg-icon align-items-stretch" style={backgroundStyle} onClick={onClickFunction}>
            {/* Job Container */}
            <div className="py-2 pt-1 pb-3 bg-icon-inside d-flex  align-items-stretch col-12">
              <div className="col-12 d-flex flex-column">
                <div className="job-list-content d-flex flex-column flex-fill">
                  {/* Job Title */}
                  <div className="d-flex flex-row align-items-center py-3">
                    <h4 className="ps-3 mb-0 mt-1">{job.title}</h4>
                  </div>

                  Flipped
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div >
        </div>
      </ReactCardFlip>

    </div>
  )
}

function JobInfoTag({ className, text }) {
  return (
    <span className="profile-attribute-bubble rounded px-2 py-1 me-1 my-1">
      <i className={className}></i> {text}
    </span>
  )
}