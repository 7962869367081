export const ACTIONS = {
  SET_TAB_INDEX: "SET_TAB_INDEX",
  SET_PROCESSING: "SET_PROCESSING",
  SET_PROFILE: "SET_PROFILE",
  SET_VALIDATION_ERRORS: "SET_VALIDATION_ERRORS",

  // Form Values
  SET_FIELD: "SET_FIELD",
  SET_SKILLS: "SET_SKILLS",
  SET_JOB_TYPES: "SET_JOB_TYPES",

  // Experience
  ADD_EXPERIENCE: "ADD_EXPERIENCE",
  REMOVE_EXPERIENCE: "REMOVE_EXPERIENCE",
  UPDATE_EXPERIENCE: "UPDATE_EXPERIENCE",

  // Education
  ADD_EDUCATION: "ADD_EDUCATION",
  REMOVE_EDUCATION: "REMOVE_EDUCATION",
  UPDATE_EDUCATION: "UPDATE_EDUCATION",

  // Form Submit
  FORM_SUBMIT_START: "FORM_SUBMIT_START",
  FORM_SUBMIT_SUCCESS: "FORM_SUBMIT_SUCCESS",
  FORM_SUBMIT_FAILURE: "FORM_SUBMIT_FAILURE",
};

// Reducer
export const ProfileFormReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TAB_INDEX:
      return { ...state, tabIndex: action.payload };
    case ACTIONS.SET_PROCESSING:
      return { ...state, processing: action.payload };
    case ACTIONS.SET_PROFILE:
      return { ...state, profile: action.payload };
    case ACTIONS.SET_VALIDATION_ERRORS:
      return { ...state, validation_errors: action.payload };

    // Form Values
    case ACTIONS.SET_FIELD:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };

    // Add Experience
    case ACTIONS.ADD_EXPERIENCE:
      return {
        ...state,
        profile: {
          ...state.profile,
          experiences: [...state.profile.experiences, action.payload],
        },
      };

    // Remove Experience
    case ACTIONS.REMOVE_EXPERIENCE:
      const newExperience = [
        ...state.profile.experiences.slice(0, action.payload),
        ...state.profile.experiences.slice(action.payload + 1),
      ];

      return {
        ...state,
        profile: {
          ...state.profile,
          experiences: newExperience,
        },
      };

    // Update Experience
    case ACTIONS.UPDATE_EXPERIENCE: {
      const { index, experience } = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          experiences: state.profile.experiences.map((exp, i) =>
            i === index ? experience : exp
          ),
        },
      };
    }

    case ACTIONS.SET_SKILLS:
      return {
        ...state,
        profile: {
          ...state.profile,
          skills: action.payload,
          skill_ids: action.payload,
        },
      };

    // SET_JOB_TYPES
    case ACTIONS.SET_JOB_TYPES:
      return {
        ...state,
        profile: {
          ...state.profile,
          job_type_ids: action.payload,
        },
      };

    // Add Education
    case ACTIONS.ADD_EDUCATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          educations: [...state.profile.educations, action.payload],
        },
      };

    // Remove Education
    case ACTIONS.REMOVE_EDUCATION:
      const newEducation = [
        ...state.profile.educations.slice(0, action.payload),
        ...state.profile.educations.slice(action.payload + 1),
      ];

      return {
        ...state,
        profile: {
          ...state.profile,
          educations: newEducation,
        },
      };

    // Update Education
    case ACTIONS.UPDATE_EDUCATION: {
      const { index, education } = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          educations: state.profile.educations.map((edu, i) =>
            i === index ? education : edu
          ),
        },
      };
    }

    // Form Submit
    case ACTIONS.FORM_SUBMIT_START:
      return { ...state, processing: true, error: false };
    case ACTIONS.FORM_SUBMIT_SUCCESS:
      return { ...state, processing: false, error: false };
    case ACTIONS.FORM_SUBMIT_FAILURE:
      return { ...state, processing: false, error: true };

    default:
      return state;
  }
};
