import * as React from "react";
import { ApplicationInvitationsProps } from "./types";
import { InviteComponent } from "./InviteComponent";

const ApplicationInvitations: React.FC<ApplicationInvitationsProps> = ({
  invites,
  authenticity_token
}) => (
  <>{invites.map(invite => (
    <InviteComponent
      key={invite.id}
      invite={invite}
      authenticity_token={authenticity_token}
    />
  ))}</>
);

export default ApplicationInvitations;