import * as React from 'react';
import Select from 'react-select';

import { ProfileFormContext } from '../../ProfileEditForm';
import { ACTIONS } from '../ProfileFormReducer';

export default function Skills() {
  const [state, dispatch] = React.useContext(ProfileFormContext);
  const { skills, skill_categories, profile } = state;

  const handleSelectChange = (selected, category_skills) => {
    // Get selected skills
    const selected_skills = category_skills.filter(
      skill => selected.map(s => s.value).includes(skill.id)
    );

    // Get unselected skills
    const unselected_skills = category_skills.filter(
      skill => !selected.map(s => s.value).includes(skill.id)
    );

    // Update skills directly through reducer
    const newSkillState = [...profile.skill_ids]
      .concat(selected_skills.map(s => s.id).filter(s => !profile.skill_ids.includes(s)))
      .filter(s => !unselected_skills.map(u => u.id).includes(s));

    dispatch({ type: ACTIONS.SET_SKILLS, payload: newSkillState });
  };

  return (
    <div>
      <label htmlFor="skills" className="form-label">Skills</label>
      <div>
        {skill_categories.map(category => {
          const category_skills = skills.filter(skill => skill.category === category)

          // Skip if category.name == "Language Proficiency (other than English)" & skill with id of 55 is not in job_skills
          const category_lang = category === "Language Proficiency (other than English)"
            && !profile.skill_ids.includes(55)

          if (category_lang) {
            return null
          }

          const select_skills = category_skills.sort((a, b) => a.name.localeCompare(b.name))
            .map(skill => ({
              value: skill.id,
              label: skill.name,
              color: skill.color
            }))

          const customStyles = {
            option: (styles) => {
              return { ...styles, }
            },

            multiValueLabel: ({ }, { data }) => {
              return {
                backgroundColor: data.color,
                color: 'white',
                borderRadius: '5px 0 0 5px',
                paddingLeft: 8,
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 3,
              }
            },

            multiValueRemove: (styles, { data }) => {
              return {
                ...styles,
                backgroundColor: data.color,
                color: 'white',
                borderRadius: '0 5px 5px 0',
                paddingRight: 5,
                paddingLeft: 4,
                marginLeft: -1,
              }
            },
          }

          const value = select_skills.filter(
            skill => profile.skill_ids.includes(skill.value)
          )

          const onChangeFunc = (selected) => {
            handleSelectChange(selected, category_skills)
          }

          return (
            <div className="mb-4" key={category}>
              <h6 className="fw-bold">{category}</h6>
              <Select
                styles={customStyles}
                options={select_skills}
                isMulti
                value={value}
                onChange={onChangeFunc}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}