import * as React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProfileJobTypes from "./ProfileJobTypes";
import ProfileHiringTypes from "./ProfileHiringTypes";
import ProfileExperience from "../FormComponents/ProfileExperience";
import axios from "axios";
import InputMask from "react-input-mask";

interface Profile {
  first_name: string;
  last_name: string;
  phone: string;
  zip: string;
  position_type_ids: number[];
  job_type_ids: number[];
  experiences_attributes: Experience[];
  resume: File | string;
  sms_optin: boolean;
}

interface Experience {
  employer: string;
  title: string;
  start_date: string;
  end_date: string;
  description: string;
  current: boolean;
}

interface ValidationErrors {
  first_name: boolean;
  last_name: boolean;
  phone: boolean;
  zip: boolean;
  positions: boolean;
  availability: boolean;
  experience_or_resume: boolean;
}

interface State {
  processing: boolean;
  experience_selected: boolean;
  experience: boolean;
  tabIndex: number;
  experience_array: Experience[];
  profile: Profile;
  validation_errors: ValidationErrors;
  serverErrors: string[];
}

interface Props {
  job_types: Array<{ id: number; name: string }>;
  hiring_types: Array<{ id: number; name: string }>;
  authenticity_token: string;
}

interface ValidationErrorProps {
  display: boolean;
  message: string;
}

const initialState: State = {
  processing: false,
  experience_selected: false,
  experience: false,
  tabIndex: 0,
  experience_array: [],
  profile: {
    first_name: "",
    last_name: "",
    phone: "",
    zip: "",
    position_type_ids: [],
    job_type_ids: [],
    experiences_attributes: [],
    resume: "",
    sms_optin: true,
  },
  validation_errors: {
    first_name: false,
    last_name: false,
    phone: false,
    zip: false,
    positions: false,
    availability: false,
    experience_or_resume: false,
  },
  serverErrors: [],
};

const checkForErrors = (tabIndex: number, validation_errors: ValidationErrors): boolean => {
  let errors = false;
  const {
    first_name,
    last_name,
    phone,
    zip,
    positions,
    availability,
    experience_or_resume,
  } = validation_errors;

  switch (tabIndex) {
    case 1:
      errors = first_name || last_name || phone || zip;
      break;

    case 2:
      errors = positions;
      break;

    case 3:
      errors = availability;
      break;

    case 4:
      errors = experience_or_resume;
      break;

    default:
      errors = false;
      break;
  }

  return errors;
};

const ProfileForm: React.FC<Props> = ({ job_types, hiring_types, authenticity_token }) => {
  const [state, setState] = React.useState<State>({ ...initialState });

  const selectTab = (tabIndex: number): void => {
    let errors = false;

    const validation_fields = Object.keys(state.validation_errors);

    let _cv;

    validation_fields.forEach((f) => {
      _cv = checkValidations({
        profile: state.profile,
        field: f as keyof ValidationErrors,
        validation_errors: state.validation_errors,
      });
    });

    const validation_errors = _cv;

    setState((state) => ({ ...state, validation_errors }));

    errors = checkForErrors(tabIndex, validation_errors);

    if (!errors)
      setState((state) => ({
        ...state,
        tabIndex,
        validation_errors: initialState.validation_errors,
      }));
  };

  const unselectExperienceAndResume = (): void => {
    console.log("Resetting experience and resume");
    setState((state) => ({
      ...state,
      experience: false,
      experience_selected: false,
      experience_array: [],
      profile: {
        ...state.profile,
        experiences_attributes: [],
        resume: ""
      }
    }));
  };

  const selectExperience = (): void =>
    setState((state) => ({
      ...state,
      experience: true,
      experience_selected: true,
      profile: {
        ...state.profile,
        resume: ""
      }
    }));

  const selectResume = (): void =>
    setState((state) => ({
      ...state,
      experience: false,
      experience_selected: true,
      experience_array: [],
      profile: {
        ...state.profile,
        experiences_attributes: []
      }
    }));

  const removeMaskCharacters = (maskedString: string | null): string => {
    return maskedString == null ? "" : maskedString.replace(/\D+/g, "");
  };

  const updateProfile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    let val;
    if (name == "zip") {
      const re = /^[0-9\b]+$/;
      if ((value == "" || re.test(value)) && value.length < 6) val = value;
    } else {
      val = value;
    }

    const profile = { ...state.profile, [name]: val };
    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: name as keyof ValidationErrors,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const updateProfileCheckboxes = (name: string, value: any): void => {
    let fieldName;
    const profile = { ...state.profile, [name]: value };

    switch (name) {
      case "job_type_ids":
        fieldName = "positions";
        break;
      case "position_type_ids":
        fieldName = "availability";
        break;

      default:
        break;
    }

    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: fieldName as keyof ValidationErrors,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const updateExperience = (experience: Experience[]): void => {
    setState((state) => ({
      ...state,
      experience_array: experience,
      profile: {
        ...state.profile,
        experiences_attributes: experience.map((exp) => ({
          employer: exp.employer,
          title: exp.title,
          start_date: exp.start_date,
          end_date: exp.end_date,
          description: exp.description,
          current: exp.current,
        })),
      },
      validation_errors: checkValidations({
        profile: {
          ...state.profile,
          experiences_attributes: experience
        },
        field: "experience_or_resume" as keyof ValidationErrors,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const handleFileChange = (files: FileList | null): void => {
    if (!files || files.length === 0) return;

    const file = files[0];
    const profile = { ...state.profile, resume: file };

    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: "experience_or_resume" as keyof ValidationErrors,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const setProcessing = (processing: boolean): void =>
    setState((state) => ({ ...state, processing }));

  const submitForm = (): void => {
    const { profile } = state;
    sendProfile({
      profile,
      setProcessing,
      setState, // Pass setState to sendProfile
      authenticity_token,
      tabIndex: state.tabIndex,
      checkValidations,
      state,
    });
  };

  // var Spinner = require("react-spinkit")

  if (state.processing) {
    return (
      <center>
        <h3>Processing...</h3>
      </center>
    );
  } else {
    return (
      <Tabs
        selectedIndex={state.tabIndex}
        onSelect={selectTab}
        style={{ maxWidth: 600, margin: "auto" }}
      >
        {state.serverErrors.length > 0 && (
          <ServerErrors errors={state.serverErrors} />
        )}
        <TabList style={{ marginBottom: 30, marginTop: 30 }}>
          <Tab>General Info</Tab>
          <Tab>Positions</Tab>
          <Tab>Availability</Tab>
          <Tab>Experience</Tab>
        </TabList>

        {/* General Information */}
        <TabPanel>
          {/* First Name */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="first_name"
              id="profile_first_name"
              placeholder="First Name"
              value={state.profile.first_name}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.first_name}
              message="*Required"
            />
          </p>

          {/* Last Name */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="last_name"
              id="profile_last_name"
              placeholder="Last Name"
              value={state.profile.last_name}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.last_name}
              message="*Required"
            />
          </p>

          {/* Phone */}
          <p>
            <InputMask
              name="phone"
              className="search-field form-control"
              placeholder="Phone"
              type="text"
              mask="+1 (999) 999-9999"
              maskChar=" "
              value={state.profile.phone}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.phone}
              message="*Required"
            />
          </p>

          {/* Zip Code */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="zip"
              id="profile_zip"
              placeholder="Zip Code"
              value={state.profile.zip}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.zip}
              message="*Required"
            />
          </p>

          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => selectTab(1)}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Positions */}
        <TabPanel>
          <ProfileJobTypes
            selected={state.profile.job_type_ids}
            job_types={job_types}
            update={updateProfileCheckboxes}
          />
          <ValidationError
            display={state.validation_errors.positions}
            message="Select all that apply."
          />
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => selectTab(0)}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => selectTab(2)}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Availability */}
        <TabPanel>
          <ProfileHiringTypes
            selected={state.profile.position_type_ids}
            hiring_types={hiring_types}
            update={updateProfileCheckboxes}
          />

          {/* Checkbox - Opt-In to receive text messages about jobs */}
          <p>
            <label htmlFor="profile_sms_optin" style={{ marginLeft: 10 }}>
              <input
                type="checkbox"
                name="sms_optin"
                id="profile_sms_optin"
                checked={state.profile.sms_optin}
                onChange={(e) =>
                  updateProfileCheckboxes(e.target.name, e.target.checked)
                }
                style={{ marginRight: 10 }}
              />
              Opt-In to receive an SMS when a dental office is interested in
              your application or temp availability.
            </label>
            {/* When unchecked, display red text saying you wont receive any messages */}
            {!state.profile.sms_optin && (
              <span style={{ color: "red", marginLeft: 10, display: "block" }}>
                By opting out of SMS communications, you won't receive any SMS
                alerts when dental offices invite you to apply or temp.
              </span>
            )}
          </p>

          <ValidationError
            display={state.validation_errors.availability}
            message="Select all that apply."
          />
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => selectTab(1)}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => {
                unselectExperienceAndResume();
                selectTab(3);
              }}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Experience */}
        <TabPanel>
          <div style={{ margin: "30px auto", textAlign: "center" }}>
            {!state.experience_selected && (
              <React.Fragment>
                <a
                  href="#"
                  className="button outline btn btn-primary"
                  onClick={selectExperience}
                >
                  Enter Experience
                </a>
                <span style={{ display: "block", margin: "20px 5px" }}>Or</span>
                <a
                  href="#"
                  className="button outline btn btn-primary"
                  onClick={selectResume}
                >
                  Upload Resume
                </a>
              </React.Fragment>
            )}
            {state.experience_selected && state.experience && (
              <ProfileExperience
                experience={state.experience_array}
                update={updateExperience}
              />
            )}
            {state.experience_selected && !state.experience && (
              <div className="resume-upload-container">
                <label
                  htmlFor="fileUpload"
                  className="fileUpload btn btn-outline-primary"
                >
                  {!state.profile.resume?.name && <>Choose File</>}
                  <input
                    type="file"
                    id="fileUpload"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e.target.files)}
                    accept=".pdf,.doc,.docx,.txt"
                  />
                </label>
                {state.profile.resume?.name && (
                  <div className="selected-file mt-2">
                    Selected File: {state.profile.resume.name}
                    <button
                      className="btn btn-sm btn-outline-danger ml-2"
                      onClick={() => setState(state => ({
                        ...state,
                        profile: { ...state.profile, resume: "" }
                      }))}
                    >
                      Clear
                    </button>
                  </div>
                )}
                <ValidationError
                  display={state.validation_errors.experience_or_resume && !state.profile.resume}
                  message="Please select a resume file"
                />
              </div>
            )}
            <br />
            {state.experience_selected && (
              <div className="text-center mt-3">
                <button
                  className="btn btn-outline-secondary"
                  onClick={unselectExperienceAndResume}
                >
                  Change Selection
                </button>
              </div>
            )}
            <p>
              <ValidationError
                display={state.validation_errors.experience_or_resume}
                message="Upload your resume or enter your experience."
              />
            </p>
          </div>
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => selectTab(2)}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => submitForm()}
            >
              Submit
            </a>
          </div>
        </TabPanel>
      </Tabs>
    );
  }
};

const ValidationError: React.FC<ValidationErrorProps> = ({ display, message }) => {
  return (
    <React.Fragment>
      {display && <span style={{ color: "#555" }}>{message}</span>}
    </React.Fragment>
  );
};

const ServerErrors: React.FC<{ errors: string[] }> = ({ errors }) => {
  if (!errors.length) return null;
  return (
    <div className="alert alert-danger">
      {errors.map((error, index) => (
        <div key={index}>{error}</div>
      ))}
    </div>
  );
};

interface ValidationCheckParams {
  field: keyof ValidationErrors;
  profile: Profile;
  validation_errors: ValidationErrors;
}

const checkValidations = ({ field, profile, validation_errors }: ValidationCheckParams): ValidationErrors => {
  let errors = { ...validation_errors };

  // Guard against undefined or null profile
  if (!profile) {
    return errors;
  }

  switch (field) {
    // Should not be blank
    case "first_name":
    case "last_name":
      errors[field] = !profile[field] || profile[field].length < 1;
      break;
    case "phone":
      errors[field] = !profile[field] || profile[field].replace(/\D/g, '').length < 10;
      break;
    case "zip":
      errors[field] = !profile[field] || profile[field].length < 5;
      break;

    // Should have a value
    case "positions":
      errors[field] = !profile["job_type_ids"] || profile["job_type_ids"].length < 1;
      break;
    case "availability":
      errors[field] = !profile["position_type_ids"] || profile["position_type_ids"].length < 1;
      break;
    case "experience_or_resume":
      let allBlank = true;

      if (profile.experiences_attributes && profile.experiences_attributes.length > 0) {
        allBlank = profile.experiences_attributes.every((exp) =>
          !exp || Object.keys(exp).every((k) => !exp[k] || exp[k] === "")
        );
      }

      const hasExperience = profile.experiences_attributes &&
        profile.experiences_attributes.length > 0 &&
        !allBlank;

      const hasResume = profile.resume &&
        (typeof profile.resume === 'string' ? profile.resume.length > 0 : true);

      errors[field] = !hasResume && !hasExperience;
      break;

    default:
      break;
  }

  return errors;
};

interface SendProfileParams {
  profile: Profile;
  setProcessing: (processing: boolean) => void;
  setState: React.Dispatch<React.SetStateAction<State>>;
  authenticity_token: string;
  tabIndex: number;
  checkValidations: (params: ValidationCheckParams) => ValidationErrors;
  state: State;
}

const sendProfile = ({
  profile,
  setProcessing,
  setState,
  authenticity_token,
  tabIndex,
  checkValidations,
  state,
}: SendProfileParams): void => {
  let errors = false;

  const validation_fields = Object.keys(state.validation_errors);

  let _cv = {};

  validation_fields.forEach((f) => {
    _cv = checkValidations({
      profile: state.profile,
      field: f as keyof ValidationErrors,
      validation_errors: _cv || state.validation_errors,
    });
  });

  errors = validation_fields.some((f) => _cv[f as keyof ValidationErrors] === true);

  if (!errors) {
    setProcessing(true);
    let formData = new FormData();

    Object.keys(profile).forEach((key) => {
      const isArray = [
        "position_type_ids",
        "job_type_ids",
        "experiences_attributes",
      ].includes(key);

      // Resume
      if (key === "resume" && profile[key] !== "") {
        if (typeof profile[key] === 'object' && profile[key] !== null) {
          formData.append(`profile[${key}]`, profile[key], profile[key].name);
        }
      } else if (isArray) {
        // Positions
        if (key === "position_type_ids") {
          profile[key].forEach((pk) =>
            formData.append(`profile[${key}][]`, pk),
          );
        }
        // Jobs
        if (key === "job_type_ids") {
          profile[key].forEach((pk) =>
            formData.append(`profile[${key}][]`, pk),
          );
        }
        // Experience
        if (key === "experiences_attributes" && profile[key] && profile[key].length > 0) {
          profile.experiences_attributes.forEach((exp, index) => {
            Object.keys(exp).forEach(expKey => {
              const value = exp[expKey] === null ? "" : exp[expKey];
              formData.append(
                `profile[experiences_attributes][${index}][${expKey}]`,
                value
              );
            });
          });
        }
      } else {
        formData.append(`profile[${key}]`, profile[key]);
      }
    });

    const options = {
      headers: {
        "X-CSRF-Token": authenticity_token,
        "Content-type": "multipart/form-data",
      },
    };

    // Debug FormData contents
    console.log("Profile data being submitted:");
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    axios
      .post("/profiles", formData, options) // Remove .json extension
      .then((res) => {
        console.log("Profile submitted successfully:", res);
        const url = res.request.responseURL;
        if (url) window.location.href = url;
      })
      .catch((err) => {
        console.error("Error submitting profile:", err);
        setProcessing(false);

        // Enhanced error handling
        if (err.response?.status === 422) {
          const errorData = err.response.data;
          let serverErrors: string[] = [];

          if (Array.isArray(errorData.errors)) {
            serverErrors = errorData.errors;
          } else if (typeof errorData.errors === 'object') {
            // Handle nested error objects
            serverErrors = Object.entries(errorData.errors)
              .map(([key, value]) => `${key}: ${value}`);
          } else if (errorData.error) {
            serverErrors = [errorData.error];
          } else {
            serverErrors = ['An error occurred while submitting the form'];
          }

          setState(prevState => ({
            ...prevState,
            serverErrors
          }));
        }
      });
  }
};

export default ProfileForm;
