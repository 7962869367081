import * as React from 'react';
import { $getRoot, $createParagraphNode, $createTextNode, $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND, ElementNode } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ParagraphNode, TextNode } from 'lexical';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import type { HeadingTagType } from '@lexical/rich-text';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';

import './LexicalRichTextareaEditor.css';

type EditorProps = {
  editorState: string;
  onChangeDescription: (html: string) => void;
}

const theme = {
  paragraph: 'editor-paragraph',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline',
  },
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
  },
  quote: 'editor-quote',
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
  },
  code: 'editor-code',
  table: 'editor-table',
  tableCell: 'editor-table-cell',
  tableRow: 'editor-table-row',
};

function onError(error: Error) {
  console.error(error);
}

function initialEditorState(editor: any, html: string) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  const nodes = $generateNodesFromDOM(editor, dom);
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    root.append(...nodes);
  });
}

const sanitizeHtml = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = html;

  // Remove Lexical classes from elements
  const elements = div.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.removeAttribute('class');
    element.removeAttribute('style');
  }

  return div.innerHTML;
};

export const LexicalRichTextareaEditor: React.FC<EditorProps> = ({ editorState, onChangeDescription }) => {
  const initialConfig = {
    namespace: 'DDSJobsEditor',
    theme,
    onError,
    nodes: [
      ParagraphNode,
      TextNode,
      HeadingNode,
      QuoteNode,
      ListItemNode,
      ListNode,
      CodeHighlightNode,
      CodeNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      LinkNode,
      AutoLinkNode
    ],
    editorState: (editor) => {
      editor.update(() => {
        if (!editorState) {
          const root = $getRoot();
          const paragraph = $createParagraphNode();
          paragraph.append($createTextNode(''));
          root.append(paragraph);
          return;
        }

        try {
          const parser = new DOMParser();
          const dom = parser.parseFromString(editorState, 'text/html');
          const nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.clear();
          nodes.forEach(node => root.append(node));
        } catch (error) {
          console.error('Error parsing HTML:', error);
          const root = $getRoot();
          const paragraph = $createParagraphNode();
          paragraph.append($createTextNode(editorState));
          root.append(paragraph);
        }
      });
    },
  };

  return (
    <div className="editor-container">
      <LexicalComposer initialConfig={initialConfig}>
        <div className="editor-inner">
          <ToolbarPlugin />
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<div className="editor-placeholder">Enter description...</div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin bulletListClassName="editor-list-ul"
            nestedBulletListClassName="editor-nested-list"
            listItemClassName="editor-listitem"
            checkListClassName="editor-checklist"
            orderedListClassName="editor-list-ol"
            nestedOrderedListClassName="editor-nested-list" />
          <OnChangePlugin onChange={(editorState, editor) => {
            editorState.read(() => {
              try {
                const htmlString = $generateHtmlFromNodes(editor);
                if (htmlString) {
                  const cleanHtml = sanitizeHtml(htmlString);
                  onChangeDescription(cleanHtml);
                }
              } catch (error) {
                console.error('Error generating HTML:', error);
              }
            });
          }} />
        </div>
      </LexicalComposer>
    </div>
  );
};

function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();

  const formatText = (format: string) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
  };

  const formatElement = (blockType: string) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      // If no text is selected, select the parent block
      if (selection.anchor.offset === selection.focus.offset) {
        const anchorNode = selection.anchor.getNode();
        const parentBlock = anchorNode.getParent() || anchorNode;

        if (parentBlock) {
          selection.anchor.setPoint(parentBlock.getKey(), 0, 'element');
          selection.focus.setPoint(parentBlock.getKey(), parentBlock.getTextContentSize(), 'element');
        }
      }

      if (blockType === 'ul' || blockType === 'ol') {
        const listType = blockType === 'ul' ? 'bullet' : 'number';
        const parentList = selection.anchor.getNode().getParent();

        if (parentList?.getType() === 'list') {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        } else {
          if (blockType === 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          } else {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          }
        }
      } else if (blockType === 'quote') {
        const quote = $createQuoteNode();
        quote.append($createTextNode(selection.getTextContent()));
        selection.insertNodes([quote]);
      } else if (blockType.startsWith('h')) {
        const tag = blockType as HeadingTagType;
        if (['h1', 'h2', 'h3'].includes(tag)) {
          const heading = $createHeadingNode(tag);
          heading.append($createTextNode(selection.getTextContent()));
          selection.insertNodes([heading]);
        }
      } else {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, blockType);
      }
    });
  };

  return (
    <div className="toolbar btn-toolbar bg-light border rounded-top p-2" role="toolbar">
      <div className="btn-group me-2" role="group">
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatText('bold'); }}>
          <i className="fas fa-bold"></i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatText('italic'); }}>
          <i className="fas fa-italic"></i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatText('underline'); }}>
          <i className="fas fa-underline"></i>
        </button>
      </div>

      <div className="btn-group me-2" role="group">
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('h1'); }}>
          <i className="fas fa-heading">1</i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('h2'); }}>
          <i className="fas fa-heading">2</i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('h3'); }}>
          <i className="fas fa-heading">3</i>
        </button>
      </div>

      <div className="btn-group me-2" role="group">
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('ul'); }}>
          <i className="fas fa-list-ul"></i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('ol'); }}>
          <i className="fas fa-list-ol"></i>
        </button>
      </div>

      <div className="btn-group" role="group">
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatElement('quote'); }}>
          <i className="fas fa-quote-right"></i>
        </button>
        <button type="button" className="btn btn-sm btn-light" onClick={(e) => { e.preventDefault(); formatText('code'); }}>
          <i className="fas fa-code"></i>
        </button>
      </div>
    </div>
  );
}

export default LexicalRichTextareaEditor;
