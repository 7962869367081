import * as React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import GeneralInfo from "./ProfileFormComponents/GeneralInfo";
import Experience from "./ProfileFormComponents/Experience";
import Availability from "./ProfileFormComponents/Availability";
import Documents from "./ProfileFormComponents/Documents";
import { ProfileCompleteness } from "./components/ProfileCompleteness";
import { submitProfile } from "./utils/profileSubmission";
import { ACTIONS, ProfileFormReducer } from "./ProfileFormComponents/ProfileFormReducer";
import { Profile, InitialState, DispatchAction } from "./types/Profile";

function initialState(
  profile: Profile,
  job_types: any[],
  hiring_types: any[],
  skills: any[],
  skill_categories: any[],
  authenticity_token: string,
): InitialState {
  const educations = profile.educations || [];
  const experiences = profile.experiences || [];

  return {
    options: { job_types, hiring_types },
    authenticity_token,
    skills,
    skill_categories,
    processing: false,
    error: false,
    experience_selected: false,
    experience: false,
    tabIndex: 0,
    profile: {
      first_name: "",
      last_name: "",
      phone: "",
      zip: "",
      position_type_ids: profile.position_types?.map((p: any) => p.id) || [],
      skill_ids: profile.skills?.map((s: any) => s.id) || [],
      job_type_ids: profile.job_types?.map((j: any) => j.id) || [],
      ...profile,
      experiences,
      educations,
    },
    validation_errors: {
      first_name: false,
      last_name: false,
      phone: false,
      zip: false,
      positions: false,
      availability: false,
      experience_or_resume: false,
    },
  };
}

export const ProfileFormContext = React.createContext<[InitialState, React.Dispatch<DispatchAction>]>([{} as InitialState, () => { }]);

interface ProfileFormProps {
  skills: any[];
  skill_categories: any[];
  profile: Profile;
  job_types: any[];
  hiring_types: any[];
  authenticity_token: string;
}

export default function ProfileForm({
  skills,
  skill_categories,
  profile,
  job_types,
  hiring_types,
  authenticity_token,
}: ProfileFormProps) {
  const [state, dispatch] = React.useReducer(
    ProfileFormReducer,
    initialState(
      profile,
      job_types,
      hiring_types,
      skills,
      skill_categories,
      authenticity_token,
    ),
  );

  const nextTab = () => {
    const element = document.getElementById("profileeditformcontainer");
    element?.scrollIntoView({ behavior: "smooth", block: "start" });

    setTimeout(() => {
      dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: state.tabIndex + 1 });
    }, 200);
  };

  const tabStyles = "react-tabs__tab text-center flex-grow-1";

  const submitForm = () => {
    submitProfile(state.profile, dispatch, authenticity_token);
  };

  React.useEffect(() => {
    document
      .getElementById("profileeditformcontainer")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [state.tabIndex]);

  return (
    <ProfileFormContext.Provider value={[state, dispatch]}>
      <div id="profileeditformcontainer">
        <ProfileCompleteness profile={state.profile} />
      </div>

      {state.processing && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          Processing...
        </div>
      )}

      {state.error && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">
              There was an error submitting your profile.
            </span>
          </div>
        </div>
      )}

      {!state.processing && !state.error && (
        <Tabs
          selectedIndex={state.tabIndex}
          onSelect={(index: number) =>
            dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: index })
          }
        >
          <TabList className="d-flex justify-content-between gap-0">
            <Tab className={tabStyles}>
              <i className="fas fa-user mr-2"></i>{" "}
              {state.tabIndex === 0 && "General Info"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-file mr-2"></i>{" "}
              {state.tabIndex === 1 && "Documents"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-briefcase mr-2"></i>{" "}
              {state.tabIndex === 2 && "Experience"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-calendar mr-2"></i>{" "}
              {state.tabIndex === 3 && "Availability"}
            </Tab>
          </TabList>

          <TabPanel>
            <GeneralInfo />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Documents />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Experience />
            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Availability />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={submitForm}
                disabled={state.processing}
              >
                Submit
              </button>
            </div>
          </TabPanel>
        </Tabs>
      )}
    </ProfileFormContext.Provider>
  );
}
