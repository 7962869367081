import * as React from "react";
import { marked } from 'marked';
import JobForm from "./JobFormContext";
import { LexicalRichTextareaEditor } from "../../FormComponents/LexicalRichTextareaEditor";

export default function Description({
  job_types,
  tabIndexFunc,
  validation,
  initialDescription = "",
  form_submit,
}) {
  const [jobState, setJobState] = React.useContext(JobForm);
  const [htmlContent, setHtmlContent] = React.useState(jobState.description || '');
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [editorKey, setEditorKey] = React.useState(0); // Add this to force remount

  const templateEditor = job_types.find(
    item => item.id === Number(jobState.job_type_id)
  )?.description_template || '';

  React.useEffect(() => {
    // Process standard markdown first, then handle custom underline syntax
    marked.use({
      extensions: [{
        name: 'underline',
        level: 'inline',
        start(src) { return src.match(/\+\+/)?.index; },
        tokenizer(src) {
          const rule = /^\+\+([^+]+)\+\+/;
          const match = rule.exec(src);
          if (match) {
            // Parse the content inside ++ for other markdown
            const innerContent = marked.parseInline(match[1]);
            return {
              type: 'underline',
              raw: match[0],
              text: innerContent,
              tokens: []
            };
          }
          return undefined;
        },
        renderer(token) {
          return `<u>${token.text}</u>`;
        }
      }]
    });
  }, []);

  const handleEditorChange = (newHtmlContent: string) => {
    setHtmlContent(newHtmlContent);
    setJobState({
      ...jobState,
      description: newHtmlContent
    });
  };

  const convertMarkdownToHtml = (markdown: string) => {
    try {
      marked.setOptions({
        breaks: true,
        gfm: true
      });
      return { __html: marked(markdown) };
    } catch (error) {
      console.error('Markdown parsing error:', error);
      return { __html: markdown };
    }
  };

  const headlineHandler = e => {
    setJobState({ ...jobState, job_title: e.target.value });
  };

  const submitJobForm = form_submit => {
    if (htmlContent.length > 33 && !validation) {
      form_submit();
    } else {
      setErrorMessage(true);
      console.error("Not all fields are filled out");
    }
  };

  const setDescriptionTemplate = markdown => {
    const html = marked(markdown, { breaks: true });
    handleEditorChange(html);
    setEditorKey(prev => prev + 1); // Force editor remount
    setPreviewTemplate(false);
  };

  const [previewTemplate, setPreviewTemplate] = React.useState(false);

  return (
    <div>
      <div className="mb-4">
        <h4 className="font-weight-bold">
          Job Headline (Optional)
        </h4>
        {jobState.description.length < 34 && errorMessage && (
          <p className="text-danger ps-0 ms-0">
            Please fill out a job description.
          </p>
        )}
        <input
          className="form-control"
          type="text "
          onChange={e => headlineHandler(e)}
          value={jobState.job_title}
        ></input>
      </div>
      <div>
        {!previewTemplate && (
          <button className="btn favorite-btn" onClick={() => setPreviewTemplate(true)}>
            Use a Template
          </button>
        )}
        {previewTemplate && !jobState.job_type_id && (
          <p className="text-danger ps-0 ms-0">Missing job type from general info.</p>
        )}
        {jobState.job_type_id && previewTemplate && (
          <div className="my-3 bg-secondary shadow p-5 rounded-3">
            <div dangerouslySetInnerHTML={convertMarkdownToHtml(templateEditor)} />
            <div className="text-center">
              <button className="btn favorite-btn mx-1" onClick={() => setPreviewTemplate(false)}>
                Close
              </button>
              <button
                className="btn favorite-btn mx-1"
                onClick={() => setDescriptionTemplate(templateEditor)}
              >
                Use this Template
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="my-2">
        <h4 className="font-weight-bold">Job Description</h4>
        <LexicalRichTextareaEditor
          key={editorKey} // Add this to force remount
          editorState={htmlContent}
          onChangeDescription={handleEditorChange}
        />
        {htmlContent.length < 34 && (
          <p className="text-danger ps-0 ms-0">
            Description must be longer than 25 characters.
          </p>
        )}
      </div>
      <button
        type="button"
        className="btn btn-primary pull-left"
        onClick={() => {
          tabIndexFunc(2)
        }}
      >
        Back
      </button>
      <button
        type="submit"
        className="btn btn-primary pull-right"
        onClick={() => {
          submitJobForm(form_submit), setErrorMessage(true)
        }}
      >
        Submit
      </button>
    </div>
  );
}