import * as React from "react"
import { GoogleApiWrapper } from "google-maps-react"
import PlacesAutocomplete from "react-places-autocomplete"

const states = [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District of Columbia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["PR", "Puerto Rico"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"],
]

const separateComponents = (components, setState) => {
    let address_components = {
        street_address: "",
        street_address_2: "",
        city: "",
        state: "",
        zip: "",
    }

    components.address_components.forEach(ac => {
        if (ac.types.includes("street_number")) {
            address_components["street_address"] = ac.long_name
        }

        if (ac.types.includes("route")) {
            address_components["street_address"] = `${address_components["street_address"]} ${ac.long_name}`
        }

        if (ac.types.includes("locality")) {
            address_components["city"] = ac.long_name
        }

        if (ac.types.includes("administrative_area_level_1")) {
            address_components["state"] = ac.long_name
        }

        if (ac.types.includes("postal_code")) {
            address_components["zip"] = ac.long_name
        }
    })

    setState(state => ({ ...state, ...address_components, }))
}

const FormContainer = props => {
    const { google, model } = props
    const [state, setState] = React.useState({
        street_address: props.street_address || "",
        street_address_2: props.street_address_2 || "",
        city: props.city || "",
        state: props.state || "",
        zip: props.zip || "",
    })

    // Function to call props.setAddress if it exists
    const setAddress = () => {
        if (props.setAddress) {
            props.setAddress(state)
        }
    }

    const handleChange = (street_address) => {
        setState(prev => ({
            ...prev,
            street_address: street_address || ""
        }))
    }

    const handleUpdate = (name, e) => {
        const value = e.target.value

        if (name === "zip") {
            const zipIsValid = value.length <= 5 && Number(value)

            if (state.zip === undefined) {
                setState(state => ({ ...state, [name]: "" }))
            }

            if (value === "" || value === undefined || zipIsValid) {
                setState(state => ({ ...state, [name]: value }))
            }

        } else {
            setState(state => ({ ...state, [name]: value }))
        }
    }

    const handleError = () => {
        if (!Number(state.zip)) {
            return <p>Must be a 5 Digit U.S Zip Code</p>
        }
    }

    const handleSelect = (street_address, placeId) => {
        setState(state => ({ ...state, street_address }))

        var request = { placeId: placeId }
        var placeRequest = new Promise((resolve, reject) => {
            new google.maps.places.PlacesService(
                document.createElement("div")
            ).getDetails(request, (place, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place)
                } else {
                    reject()
                }
            })
        }).then(response => separateComponents(response, e => setState(e)))
    }

    // Call setAddress after state is updated
    React.useEffect(() => {
        setAddress()
    }, [state])

    return (
        <PlacesAutocomplete
            value={state.street_address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={{ componentRestrictions: { country: ["us"] } }}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                const hasSuggestions = suggestions.length > 0

                return (
                    <div>
                        <div className="">
                            <label htmlFor="street_address" className="form-label">Street Address</label>
                            <input
                                {...getInputProps({ placeholder: "Street Address", className: "search-field form-control" })}
                                name={`${model}[street_address]`}
                                id={`${model}_street_address`}
                            />

                            <div className={`autocomplete-dropdown-container ${hasSuggestions ? "shadow-sm rounded-3 mb-3" : ""}`}>
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? "suggestion-item--active p-2" : "suggestion-item p-2"

                                    const style = suggestion.active
                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                        : { backgroundColor: "#ffffff", cursor: "pointer" }

                                    return (
                                        <div
                                            key={`${suggestion.placeId || suggestion.description}_${index}` || index}
                                            {...getSuggestionItemProps(suggestion, { className, style })}
                                        >
                                            <span key={`span_${index}`}>{suggestion.description}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="form-group">
                                <label htmlFor="street_address_2" className="form-label">Street Address 2</label>
                                <input
                                    id={`${model}_street_address_2`}
                                    className="search-field form-control"
                                    placeholder="Ex: Suite #"
                                    type="text"
                                    value={state.street_address_2}
                                    name={`${model}[street_address_2]`}
                                    onChange={e => handleUpdate("street_address_2", e)}
                                />

                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="city" className="form-label">City</label>
                            <input
                                id={`${model}_city`}
                                className="search-field form-control"
                                type="text"
                                value={state.city}
                                name={`${model}[city]`}
                                onChange={e => handleUpdate("city", e)}
                            />
                        </div>

                        <div className="row">
                            <StateField
                                selected={state.state}
                                model={model}
                                onChange={e => handleUpdate("state", e)}
                            />
                            <div className="col-lg-6 form-group">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input
                                    id={`${model}_zip`}
                                    className="search-field form-control"
                                    required="required"
                                    type="text"
                                    value={state.zip}
                                    name={`${model}[zip]`}
                                    onChange={e => handleUpdate("zip", e)}
                                />
                                {handleError()}

                                <br />
                            </div>
                        </div>
                    </div>

                )
            }}
        </PlacesAutocomplete >
    )
}

const StateField = ({ selected, model, onChange }) => {
    return (
        <div className="col-lg-6 form-group">
            <h4>State</h4>
            <select
                className="form-select"
                name={`${model}[state]`}
                value={selected || ""}
                onChange={onChange}
            >
                <option value="" disabled>
                    Select State
                </option>
                {states.map(st => (
                    <option key={st[0]} value={st[0]}>
                        {st[1]}
                    </option>
                ))}
            </select>
        </div>
    )
}

// Add setAddress props to FormContainer
export default GoogleApiWrapper(
    (props) => ({
        apiKey: "AIzaSyB2D_GsF52iHq1JEsTsbjI5YgS1WfCSAgo",
        libraries: ["places"],
        setAddress: props.setAddress,
    }),
)(FormContainer)
