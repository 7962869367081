import "../Common-poly"
import * as React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import ManageJobList from "./ManageJobList"

interface Job {
  id: number;
  position_type: string;
  temp_filled: boolean;
  closed: boolean;
  published: boolean;
  job_type_id: number;
  practice_id: number;
  position_type_id: number;
  temp_date?: string;
  created_at: string;
  published_at?: string;
  job_applications: {
    hidden: boolean;
    seen: boolean;
  }[];
  job_type: {
    id: number;
    title: string;
    group: string;
  };
  practice: {
    id: number;
    name: string;
  };
}

interface ManageProps {
  jobs: Job[];
  temp_jobs: Job[];
  position_types: any[];
  job_types: any[];
  practices: any[];
}

interface ManageState {
  jobs: Job[];
  temp_jobs: Job[];
}

export default function Manage({
  jobs,
  temp_jobs,
  position_types,
  job_types,
  practices,
}: ManageProps) {
  const [state, setState] = React.useState<ManageState>({ jobs, temp_jobs })

  const fetchJobs = React.useCallback(async () => {
    try {
      const res = await fetch("/jobs/manage.json")
      const data = await res.json()

      const temp_jobs = data.temp_jobs.map(job => ({
        ...job,
        temp_date: job.temp_date === null ? job.published_at : job.temp_date,
        practice: job.practice || { id: job.practice_id }, // Preserve practice data
        job_type: job.job_type
      }))

      const perm_jobs = data.perm_jobs.map(job => ({
        ...job,
        practice: job.practice || { id: job.practice_id }, // Preserve practice data
        job_type: job.job_type
      }))

      setState(prevState => ({
        ...prevState,
        jobs: perm_jobs,
        temp_jobs
      }))
    } catch (error) {
      console.error('Error fetching jobs:', error)
    }
  }, [])

  const index = window.location.hash.substr(1) === "temp" ? 1 : 0

  const onSelect = (i: number) => {
    history.replaceState(undefined, undefined, i === 1 ? "#temp" : "#perm")
  }

  React.useEffect(() => {
    const intervalId = setInterval(fetchJobs, 30000)
    return () => clearInterval(intervalId)
  }, [fetchJobs])

  return (
    <Tabs
      defaultIndex={index}
      onSelect={(index, lastIndex, event) => onSelect(index)}
    >
      <TabList className="manageTabList border-0">
        <Tab>My Permanent Jobs</Tab>
        <Tab className="temp_tab react-tabs__tab">My Temp Jobs</Tab>
      </TabList>
      <TabPanel>
        <ManageJobList
          jobs={state.jobs}
          position_types={position_types}
          job_types={job_types}
          practices={practices}
          temp={false}
        />
      </TabPanel>
      <TabPanel>
        <ManageJobList
          jobs={state.temp_jobs}
          position_types={position_types}
          job_types={job_types}
          practices={practices}
          temp={true}
        />
      </TabPanel>
    </Tabs>
  )
}
