import * as React from "react";
import DataTable from "react-data-table-component";

const dataColumns = () => [
  {
    name: "Name",
    selector: "fullname",
    sortable: true,
    cell: (row) => (
      <a
        href={`/profiles/${row.id}`}
        style={{ display: "flex", width: "100%" }}
      >
        <img
          style={{
            padding: "5px 0",
            maxWidth: 50,
            maxHeight: 50,
            borderRadius: "50%",
            flex: 1,
          }}
          src={row.avatar}
          alt=""
        />
        <span
          style={{
            fontSize: 20,
            lineHeight: "20px",
            padding: "20px 10px",
            flex: 6,
          }}
        >
          {row.fullname}
        </span>
      </a>
    ),
    // maxWidth: '400px',
    minWidth: "300px",
  },
  {
    name: "Job Type",
    selector: "job_types",
    sortable: true,
    cell: (row) => row.job_types.map((jt) => jt.title).join(", "),
  },
  {
    name: "Position Type",
    selector: "position_types",
    sortable: true,
    cell: (row) => {
      const content = row.position_types.map((jt) => jt.name).join(", ");
      return content === "" ? "" : content;
    },
  },
  {
    name: "Location",
    selector: "zip",
    sortable: true,
    cell: (row) => `${row.city}, ${row.state}`,
  },
  {
    name: "Actions",
    cell: (row) => {
      return (
        <div>
          <a href="#" className="button">
            Request Temp
          </a>
          <a href="#" className="button">
            Invite to Apply
          </a>
        </div>
      );
    },
    minWidth: "350px",
  },
];

const FavoriteProfiles = ({ favorites }) => {
  const [state, setState] = React.useState({ favorites, table: {} });
  const updateTableState = (state) => setState({ table: state });
  const columns = dataColumns();

  return (
    <div>
      <DataTable
        data={favorites}
        columns={columns}
        onSelectedRowsChange={updateTableState}
        defaultSortField="created_at"
        defaultSortAsc={false}
        responsive
      />
    </div>
  );
};

export default FavoriteProfiles;
