import * as React from 'react'
import Spinner from 'react-spinkit'
import ReactTooltip from "react-tooltip";
import { ListContext } from '../Profile/ProfileList'
import { ProfileListContext } from '../Profile/ProfileList'

// Add Favorite API Function
const AddFavorite = ({ profile_id, authenticity_token, callback, job_id, user_id }) => {
  const url = job_id ? `/profiles/${profile_id}/add_block_candidate/${job_id}` : `/profiles/${profile_id}/remove_block_candidate_user/${user_id}`;
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticity_token
    },
  })
    .then(res => res.json())
    .then(res => {
      callback(res.status.favorited);
    })
}

// Add Favorite API Function
const RemoveFavorite = ({ profile_id, authenticity_token, callback, job_id, user_id }) => {
  const url = job_id ? `/profiles/${profile_id}/remove_block_candidate/${job_id}` : `/profiles/${profile_id}/add_block_candidate_user/${user_id}`;
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticity_token
    },
  })
    .then(res => res.json())
    .then(res => {
      callback(res.status.favorited);
    })
}

// Add Block Button
const AddBlockButton = ({ profile, loading, favorited, onClick }) => {
  const buttonColor: string = "#1c5ef7"

  return (
    <>
      {!favorited && (
        <a data-tip
          data-for={`help-block`}
          className="text-decoration-none block-button"
          onClick={onClick}>
          <i className="fad fa-ban"></i> Block
        </a>
      )}
      {favorited && (
        <a data-tip
          data-for={`help-block`}
          className="text-decoration-none block-button blocked"
          onClick={onClick}>
          <i className="fad fa-check"></i> Blocked
        </a>
      )}
    </>
  )
}

// Favorite Component
const AddFavoriteCandidate = ({
  profile,
  job_id = undefined,
  user_id = undefined,
  favorited_init = false,
  authenticity_token,
  blockedCallback = () => { }
}) => {
  const [state, setState] = React.useState({
    favorited: favorited_init,
    loading: false
  });

  const [context, setContext] = React.useContext(ListContext);

  const addBanToContext = (id) =>
    setContext({ ...context, blocked: [...context.blocked, id] })

  const updateFavoriteState = favorited =>
    setState({
      favorited: favorited,
      loading: false
    })

  const RemoveFavoriteHandler = (e) => {
    e.stopPropagation();
    setState({ ...state, loading: true });
    RemoveFavorite({
      user_id,
      profile_id: profile.id,
      job_id: job_id,
      authenticity_token: authenticity_token,
      callback: f => updateFavoriteState(f)
    });
  }

  const AddFavoriteHandler = (e) => {
    e.stopPropagation();
    setState({ ...state, loading: true });
    AddFavorite({
      user_id,
      profile_id: profile.id,
      job_id: job_id,
      authenticity_token: authenticity_token,
      callback: f => updateFavoriteState(f)
    });
    blockedCallback();
    addBanToContext(profile.id)
  }

  return (
    <React.Fragment>
      <AddBlockButton
        profile={profile}
        favorited={state.favorited}
        loading={state.loading}
        onClick={state.favorited ? RemoveFavoriteHandler : AddFavoriteHandler}
      />

      <ReactTooltip
        id={`help-block`}
        type="info"
        effect="solid"
        place="bottom"
      >
        Block from appearing in recommended candidates
      </ReactTooltip>
    </React.Fragment>
  );
}

export default AddFavoriteCandidate