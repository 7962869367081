import * as React from "react";
import moment from "moment-timezone"; // Changed import statement
import { InviteProps } from "./types";

const styles = {
  container: { flex: 1 },
  quickApplyButton: { float: "right" as const },
  ignoreButton: {
    backgroundColor: "grey",
    float: "right" as const,
    marginRight: 10,
  }
};

export const InviteComponent: React.FC<InviteProps> = ({ invite, authenticity_token }) => {
  const [ignored, setIgnored] = React.useState(invite.ignored);
  const [error, setError] = React.useState<string | null>(null);
  const { job } = invite;

  // Ensure created_at and timezone exist before formatting
  const applicationDate = React.useMemo(() => {
    if (!invite.created_at || !job.practice.timezone) {
      return 'Date unavailable';
    }
    return moment.tz(invite.created_at, job.practice.timezone).format("llll");
  }, [invite.created_at, job.practice.timezone]);

  const handleIgnore = async () => {
    try {
      setError(null);
      const response = await fetch(`/application_invites/${invite.id}.json`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": authenticity_token,
        },
        body: JSON.stringify({ ignore: !ignored }),
      });

      if (response.ok) {
        setIgnored(!ignored);
      } else {
        throw new Error('Failed to update invite status');
      }
    } catch (error) {
      console.error("Error updating invite:", error);
      setError('Failed to update invite. Please try again.');
    }
  };

  return (
    <div className="ManageApplicantContainer">
      <div className="application" style={styles.container}>
        <div className="app-content">
          <div className="info">
            {error && (
              <div className="error-message" style={{ color: 'red', marginBottom: 10 }}>
                {error}
              </div>
            )}
            <a href={`/jobs/${job.id}`}>
              <img src={job.practice.avatar_url} alt={`${job.practice.name} logo`} />
            </a>
            <span>
              <a href={`/jobs/${job.id}`}>{job.job_type.title}</a>
            </span>

            <ul>
              <li>
                <i className="fa fa-briefcase" /> {job.practice.name}
              </li>
              <li>
                <i className="fa fa-map-marker" /> {job.practice.city}, {job.practice.state}
              </li>
              {job.compensation_full && (
                <li>
                  <i className="fa fa-money" /> {job.compensation_full}
                </li>
              )}
              <li>
                <i className="fa fa-calendar" /> Invited {applicationDate}
              </li>
            </ul>

            <a
              className="send button"
              target="_blank"
              rel="noopener noreferrer"
              href={`/jobs/${job.id}/apply`}
              style={styles.quickApplyButton}
            >
              Quick Apply
            </a>

            <button
              className="send button"
              onClick={handleIgnore}
              style={styles.ignoreButton}
              disabled={!!error}
            >
              {ignored ? "Ignored" : "Ignore"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};