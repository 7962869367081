import { Profile, ProfileData, DispatchAction } from '../types/Profile';
import { ACTIONS } from '../ProfileFormComponents/ProfileFormReducer';
import DOMPurify from "dompurify";

export const submitProfile = (
  profile: Profile,
  dispatch: React.Dispatch<DispatchAction>,
  authenticity_token: string,
) => {
  const profileData: ProfileData = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    phone: profile.phone,
    phone_ext: profile.phone_ext,
    educations_attributes: profile.educations.map((edu) => ({
      ...edu,
      _destroy: edu._destroy || false,
    })),
    experiences_attributes: profile.experiences.map((exp) => ({
      ...exp,
      _destroy: exp._destroy || false,
    })),
    years_experience: profile.years_experience,
    background: profile.background,
    street_address: profile.street_address,
    street_address_2: profile.street_address_2,
    city: profile.city,
    state: profile.state,
    zip: profile.zip,
    position_type_ids: profile.position_type_ids,
    skill_ids: profile.skill_ids,
    job_type_ids: profile.job_type_ids,
    hourly_rate: profile.hourly_rate,
    maximum_traveling_distance: profile.maximum_traveling_distance,
  };

  const url = `/profiles/${profile.id}.json`;
  const data = { profile: profileData };

  dispatch({ type: ACTIONS.FORM_SUBMIT_START });

  return fetch(url, {
    method: 'put',
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        dispatch({ type: ACTIONS.FORM_SUBMIT_SUCCESS });
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((response) => {
      const api_url = response.api_url.replace(".json", "");
      const whitelist = [
        "directdental.com", 
        "test.directdental.com", 
        "veracode.directdental.com",
        "localhost"
      ];
      const url = new URL(api_url, window.location.origin);
      if (whitelist.some(domain => url.hostname === domain || url.hostname.endsWith("." + domain))) {
        window.location.href = DOMPurify.sanitize(url.href);
      } else {
        console.error("Untrusted URL:", url.hostname);
        throw new Error(`Invalid redirect URL: ${url.hostname}`);
      }
    })
    .catch((error) => {
      console.error("Error during profile submission:", error);
      dispatch({ type: ACTIONS.FORM_SUBMIT_FAILURE });
    });
};
