import * as React from 'react';
import LexicalRichTextareaEditor from './LexicalRichTextareaEditor';

interface RichTextAreaProps {
  name: string;
  value?: string;
  rich?: boolean;
  updateFunc?: (content: string) => void;
}

const RichTextArea: React.FC<RichTextAreaProps> = ({ name, value = '', rich = true, updateFunc }) => {
  const [content, setContent] = React.useState(value);

  const handleChange = (newContent: string) => {
    setContent(newContent);
    if (updateFunc) {
      updateFunc(newContent);
    }
  };

  return (
    <div className="rich-text-area-container">
      <LexicalRichTextareaEditor
        editorState={content}
        onChangeDescription={handleChange}
      />
      <input type="hidden" name={name} value={content} />
    </div>
  );
};

export default RichTextArea;
