import "../Common-poly";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import * as moment from "moment";
import Cookies from "universal-cookie";
import { Form, Dropdown } from "react-bootstrap";

interface Job {
  id: number;
  position_type: string;
  temp_filled: boolean;
  closed: boolean;
  published: boolean;
  job_type_id: number;
  practice_id: number;
  position_type_id: number;
  temp_date?: string;
  created_at: string;
  published_at?: string;
  job_applications: JobApplication[];
  job_type: {
    id: number;
    title: string;
    group: string;
  };
  practice: {
    id: number;
    name: string;
  };
}

interface JobApplication {
  hidden: boolean;
  seen: boolean;
}

interface PositionType {
  shortname: string;
  name: string;
}

interface JobType {
  id: number;
  title: string;
  group: string;
}

interface Practice {
  id: number;
  name: string;
}

const positionType = ({
  row,
  position_types,
}: {
  row: Job;
  position_types: PositionType[];
}): string => {
  const pt = position_types.find((jt) => jt.shortname === row.position_type);
  return pt ? pt.name : "";
};

const tempType = (row: Job): boolean =>
  row.position_type === "temp" || row.position_type === "longtermtemp";

const accentColor = (temp: boolean): string => {
  return temp ? "#f1630d" : "#176fc9";
};

const jobStatus = (row: Job): string => {
  if (row.temp_filled) return "Accepted";
  if (row.closed) return "Closed";
  if (tempType(row) && row.published) return "Open";
  if (row.published) return "Published";
  return "Draft";
};

const jobActions = (row: Job, temp: boolean) => {
  const buttonStyle = { color: `${accentColor(temp)}`, margin: "0px 5px" };

  const url = `/jobs/${row.id}`;
  const clone_url = `/jobs/${row.id}/clone`;
  const edit_url = `/jobs/${row.id}/edit`;

  return (
    <div className="content">
      {!row.published && !row.closed && (
        <a href={url} style={buttonStyle} className="text-decoration-none">
          <i className="fa fa-book"></i> Publish
        </a>
      )}
      <a href={clone_url} style={buttonStyle} className="text-decoration-none">
        <i className="fa fa-book"></i> Clone
      </a>
      {!row.closed && (
        <React.Fragment>
          <a
            href={edit_url}
            style={buttonStyle}
            target="_blank"
            className="text-decoration-none"
          >
            <i className="fa fa-pencil"></i> Edit
          </a>
          <a
            data-confirm="Are you sure?"
            rel="nofollow"
            data-method="delete"
            href={url}
            style={buttonStyle}
            className="text-decoration-none"
          >
            <i className="fa fa-remove"></i> Close
          </a>
        </React.Fragment>
      )}
    </div>
  );
};

function titleField({
  row,
  job_types,
  temp,
}: {
  row: Job;
  job_types: JobType[];
  temp: boolean;
}): React.ReactNode {
  const styles: React.CSSProperties = {
    color: accentColor(temp),
    fontWeight: "bold",
    border: "1px solid #D3D3D3",
    padding: "6px",
    borderRadius: "3px",
  };
  // Use either the job_type object from API or find it in job_types array
  const jobTypeTitle = row.job_type?.title ||
    job_types.find((jt) => jt.id === row.job_type_id)?.title || "";
  const url = `/jobs/${row.id}`;
  return (
    <a href={url} style={styles} className="text-decoration-none">
      {jobTypeTitle}
    </a>
  );
}

const dataColumns = ({
  position_types,
  job_types,
  practices,
  temp,
}: {
  position_types: PositionType[];
  job_types: JobType[];
  practices: Practice[];
  temp: boolean;
}): TableColumn<Job>[] => [
    {
      name: "Job Title",
      selector: (row) => row.job_type_id,
      sortable: true,
      cell: (row) => titleField({ row, job_types, temp }),
      maxWidth: "400px",
      minWidth: "200px",
    },
    {
      name: "Practice",
      selector: (row) => row.practice_id,
      sortable: true,
      cell: (row) => {
        // First try practice from row, then find in practices array using either practice.id or practice_id
        const practice = row.practice || practices.find(p => p.id === (row.practice?.id || row.practice_id));
        const practiceName = practice?.name || "Unknown Practice";
        return (
          <a
            href={`/practices/${practice?.id}`}
            style={{ color: `${accentColor(temp)}`, fontWeight: "bold" }}
            className="text-decoration-none"
          >
            {practiceName}
          </a>
        );
      },
      maxWidth: "150px",
      minWidth: "150px",
    },
    {
      name: "Position",
      selector: (row) => row.position_type_id,
      sortable: true,
      cell: (row) => positionType({ row, position_types }),
      maxWidth: "130px",
      minWidth: "130px",
      center: true,
    },
    {
      id: 'createdAt',
      name: temp ? "Job Date" : "Created",
      selector: (row) => new Date(row.created_at).getTime(),
      sortable: true,
      cell: (row) => {
        const t = tempType(row)
          ? moment(row.temp_date).format("ll")
          : moment(row.created_at).format("ll");
        if (t === "Invalid date") {
          return moment(row.published_at || row.created_at).format("ll");
        } else {
          return t;
        }
      },
      maxWidth: "120px",
      minWidth: "120px",
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.temp_filled) return 3;
        if (row.closed) return 2;
        if (row.published) return 0;
        return 1;
      },
      sortable: true,
      cell: (row) => jobStatus(row),
      right: true,
      maxWidth: "100px",
    },
    {
      name: "Applicants",
      sortable: true,
      selector: (row) => {
        const apps = row.job_applications.filter((ja) => !ja.hidden);
        const unseen = apps.filter((ja) => !ja.seen).length;
        return unseen > 0 ? -1000000 + unseen : -unseen; // Show New App(s) first, then sort by number of new applicants
      },
      cell: (row) => {
        const apps = row.job_applications.filter((ja) => !ja.hidden);
        const seen = apps.filter((ja) => ja.seen).length;
        const unseen = apps.filter((ja) => !ja.seen).length;
        if (unseen === 0) {
          return (
            <div
              className={
                temp
                  ? "content applicant-route-btn-temp"
                  : "content applicant-route-btn"
              }
            >
              <span className={temp ? "temp" : "fulltime"}>
                <strong>
                  <a
                    href={
                      row.position_type === "temp"
                        ? `/jobs/${row.id}`
                        : `/jobs/${row.id}/applicants`
                    }
                    style={{ color: "#fff" }}
                    className="text-decoration-none"
                  >
                    <span className="seen-apps text-decoration-none">{`${seen}`}</span>{" "}
                    Applicants
                  </a>
                </strong>
              </span>
            </div>
          );
        }

        return (
          <div className={temp ? "content applicant-route-btn-temp" : "content applicant-route-btn"}>
            <span className={temp ? "temp" : "fulltime"}>
              <a
                href={
                  row.position_type === "temp"
                    ? `/jobs/${row.id}`
                    : `/jobs/${row.id}/applicants`
                }
                style={{ color: "#fff" }}
                className="text-decoration-none"
              >
                <strong>
                  <span className="new-apps text-decoration-none">{`${unseen}`}</span>{" "}
                  New App(s)
                </strong>
              </a>
            </span>
          </div>
        );
      },
      right: true,
      minWidth: "180px",
      maxWidth: "180px",
      center: true,
    },
    {
      name: "Actions",
      sortable: false,
      cell: (row) => jobActions(row, temp),
      minWidth: "280px",
    },
  ];

interface ManageJobListProps {
  jobs: Job[];
  position_types: PositionType[];
  job_types: JobType[];
  temp: boolean;
  practices: Practice[];
}

// Add custom hook for handling click outside and keyboard navigation
const useClickOutsideAndKeyboard = (
  ref: React.RefObject<HTMLDivElement>,
  onClose: () => void,
  onSelect: (index: number) => void,
  suggestions: string[],
  currentIndex: number,
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!suggestions.length) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setCurrentIndex(prevIndex =>
            Math.min(prevIndex + 1, suggestions.length - 1)
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1));
          break;
        case "Enter":
          event.preventDefault();
          if (currentIndex >= 0) {
            onSelect(currentIndex);
          }
          break;
        case "Escape":
          event.preventDefault();
          onClose();
          break;
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref, onClose, onSelect, suggestions, currentIndex, setCurrentIndex]);
};

const DROPDOWN_STYLES = {
  container: {
    position: "absolute" as const,
    width: "100%",
    zIndex: 1000,
    backgroundColor: "#fafafa",
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    marginTop: "2px",
    maxHeight: "200px",
    overflowY: "auto" as const
  },
  item: (isSelected: boolean) => ({
    cursor: "pointer",
    backgroundColor: isSelected ? "#176fc9" : "transparent",
    color: isSelected ? "white" : "inherit",
    transition: "all 0.15s ease"
  })
};

interface FilterDropdownProps<T> {
  items: T[];
  value: string;
  onChange: (value: string) => void;
  getDisplayValue: (item: T) => string;
  placeholder: string;
  show: boolean;
  onClose: () => void;
  selectedIndex: number;
  onIndexChange: (index: number) => void;
}

function FilterDropdown<T>({
  items,
  value,
  onChange,
  getDisplayValue,
  placeholder,
  show,
  onClose,
  selectedIndex,
  onIndexChange
}: FilterDropdownProps<T>) {
  const ref = React.useRef<HTMLDivElement>(null);

  useClickOutsideAndKeyboard(
    ref,
    onClose,
    (index: number) => {
      onChange(getDisplayValue(items[index]));
      onClose();
    },
    items.map(getDisplayValue),
    selectedIndex,
    onIndexChange
  );

  return (
    <div style={{ width: "250px", position: "relative", marginBottom: 0 }} ref={ref}>
      <Form.Group style={{ marginBottom: 0 }}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="browsing-input-forms"
          style={{ marginBottom: 0 }}
        />
        {items.length > 0 && value && show && (
          <div style={DROPDOWN_STYLES.container}>
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  onChange(getDisplayValue(item));
                  onClose();
                }}
                onMouseEnter={() => onIndexChange(index)}
                onMouseLeave={() => onIndexChange(-1)}
                className="px-3 py-2 dropdown-item"
                style={DROPDOWN_STYLES.item(index === selectedIndex)}
              >
                {getDisplayValue(item)}
              </div>
            ))}
          </div>
        )}
      </Form.Group>
    </div>
  );
}

interface FilterBarProps {
  filters: {
    showClosed: boolean;
    jobTitleFilter: string;
    practiceFilter: string;
    statusFilter: string;
    jobTitleSuggestions: string[];
    practiceSuggestions: string[];
  };
  onFiltersChange: (filters: any) => void;
  toggleShowClosed: () => void;
  job_types: JobType[];
  practices: Practice[];
}

function FilterBar({
  filters,
  onFiltersChange,
  toggleShowClosed,
  job_types,
  practices
}: FilterBarProps) {
  const [showJobTitleDropdown, setShowJobTitleDropdown] = React.useState(false);
  const [showPracticeDropdown, setShowPracticeDropdown] = React.useState(false);
  const [jobTitleIndex, setJobTitleIndex] = React.useState(-1);
  const [practiceIndex, setPracticeIndex] = React.useState(-1);

  const handleJobTitleFilter = (value: string) => {
    onFiltersChange({
      ...filters,
      jobTitleFilter: value,
      jobTitleSuggestions: value ? job_types
        .filter(jt => jt.title.toLowerCase().includes(value.toLowerCase()))
        .map(jt => jt.title) : []
    });
    if (value) {
      setShowJobTitleDropdown(true);
    }
  };

  const handlePracticeFilter = (value: string) => {
    onFiltersChange({
      ...filters,
      practiceFilter: value,
      practiceSuggestions: value ? practices
        .filter(p => p.name.toLowerCase().includes(value.toLowerCase()))
        .map(p => p.name) : []
    });
    if (value) {
      setShowPracticeDropdown(true);
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="d-flex gap-3">
        <FilterDropdown
          items={filters.jobTitleSuggestions}
          value={filters.jobTitleFilter}
          onChange={handleJobTitleFilter}
          getDisplayValue={(title) => title}
          placeholder="Filter by Job Title"
          show={showJobTitleDropdown}
          onClose={() => setShowJobTitleDropdown(false)}
          selectedIndex={jobTitleIndex}
          onIndexChange={setJobTitleIndex}
        />
        <FilterDropdown
          items={filters.practiceSuggestions}
          value={filters.practiceFilter}
          onChange={handlePracticeFilter}
          getDisplayValue={(name) => name}
          placeholder="Filter by Practice"
          show={showPracticeDropdown}
          onClose={() => setShowPracticeDropdown(false)}
          selectedIndex={practiceIndex}
          onIndexChange={setPracticeIndex}
        />
        <div style={{ width: "180px", position: "relative", marginBottom: 0 }}>
          <Form.Group style={{ marginBottom: 0 }}>
            <Form.Select
              value={filters.statusFilter}
              onChange={(e) => onFiltersChange({ ...filters, statusFilter: e.target.value })}
              className="browsing-input-forms"
              style={{ marginBottom: 0 }}
            >
              <option value="">All Statuses</option>
              <option value="Accepted">Accepted</option>
              <option value="Closed">Closed</option>
              <option value="Open">Open</option>
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </Form.Select>
          </Form.Group>
        </div>
      </div>
      <div className="button d-flex align-items-center">
        <input
          style={{ margin: 6 }}
          type="checkbox"
          name="showClosed"
          id="showClosed"
          checked={filters.showClosed}
          onChange={toggleShowClosed}
        />
        <label htmlFor="showClosed" style={{ fontSize: 16, margin: 0 }}>
          Show Closed Jobs
        </label>
      </div>
    </div>
  );
}

export default function ManageJobList({
  jobs,
  position_types,
  job_types,
  temp,
  practices,
}: ManageJobListProps) {
  const cookies = new Cookies();
  const showClosedJobsCookie = cookies.get("showClosedJobs");
  const showClosedJobsCookieValue: boolean =
    showClosedJobsCookie === undefined ? true : showClosedJobsCookie === "true";

  const [filters, setFilters] = React.useState({
    showClosed: showClosedJobsCookieValue,
    jobTitleFilter: "",
    practiceFilter: "",
    statusFilter: "",
    jobTitleSuggestions: [],
    practiceSuggestions: []
  });

  const toggleShowClosed = (): void => {
    const showClosedValue = !filters.showClosed;
    setFilters(prev => ({ ...prev, showClosed: showClosedValue }));
    cookies.set("showClosedJobs", showClosedValue, { path: "/" });
  };

  const columns = dataColumns({ position_types, job_types, practices, temp });

  const filteredItems = React.useMemo(() => {
    return jobs.filter((job) => {
      if (!filters.showClosed && job.closed) return false;

      if (!filters.jobTitleFilter && !filters.practiceFilter && !filters.statusFilter) {
        return true;
      }

      const jobType = job.job_type || job_types.find(jt => jt.id === job.job_type_id);
      const practice = job.practice || practices.find(p => p.id === job.practice_id);

      const matchesJobTitle = !filters.jobTitleFilter ||
        (jobType?.title?.toLowerCase().includes(filters.jobTitleFilter.toLowerCase()) ?? false);

      const matchesPractice = !filters.practiceFilter ||
        (practice?.name?.toLowerCase().includes(filters.practiceFilter.toLowerCase()) ?? false);

      const status = jobStatus(job);
      const matchesStatus = !filters.statusFilter || status === filters.statusFilter;

      return matchesJobTitle && matchesPractice && matchesStatus;
    });
  }, [jobs, filters, job_types, practices]);

  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={setFilters}
        toggleShowClosed={toggleShowClosed}
        job_types={job_types}
        practices={practices}
      />
      <DataTable
        data={filteredItems}
        columns={columns}
        defaultSortFieldId="createdAt"
        defaultSortAsc={false}
        responsive
      />
      <div className="py-5" style={{ width: "100%", display: "block" }}>
        <div className="text-center">
          <a className="btn manage-job-btn" href="#" onClick={toggleShowClosed}>
            {filters.showClosed ? "Hide" : "Show"} Closed Jobs
          </a>
        </div>
      </div>
    </div>
  );
}
