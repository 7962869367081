import React from "react";
import TextField from "./TextField";
import { LexicalRichTextareaEditor } from "./LexicalRichTextareaEditor";

const ProfileEducation = (props) => {
  const [state, setState] = React.useState({ education: props.education });
  const [, forceUpdate] = React.useState(0);

  const addEducation = () => {
    setState({
      education: [
        ...state.education,
        { schoolname: "", qualification: "", desc: "", date: "" },
      ],
    });
  };

  const updateItem = (key: string, value: string, index: number) => {
    const educations = [...state.education]; // Create a new array
    educations[index] = {
      ...educations[index],  // Create a new object
      [key]: value
    };
    setState({ education: educations });
  };

  // ...existing move and delete functions...

  return (
    <div className="form-inside">
      {state.education.map((edu, index) => (
        <Education
          key={`${index}-${edu.schoolname}-${edu.qualification}-${edu.date}`}
          {...edu}
          deleteItemFun={() => deleteItem(index)}
          moveUpFun={() => moveUpFun(index)}
          moveDownFun={() => moveDownFun(index)}
          updateSchool={(e) => updateItem("schoolname", e, index)}
          updateQual={(e) => updateItem("qualification", e, index)}
          updateDate={(e) => updateItem("date", e, index)}
          updateDesc={(html) => updateItem("desc", html, index)}
        />
      ))}
      <button
        onClick={addEducation}
        className="btn contact-done gray add-Education add-box"
      >
        <i className="fa fa-plus-circle"></i> Add Education
      </button>
    </div>
  );
};

const Education = ({
  schoolname,
  qualification,
  date,
  desc,
  index,
  deleteItemFun,
  moveUpFun,
  moveDownFun,
  updateDate,
  updateDesc,
  updateQual,
  updateSchool,
}) => {
  return (
    <div className="form boxed education-box experience-background px-3 py-3 my-3">
      <div className="offset-lg-10">
        {/* ...existing movement and delete buttons... */}
      </div>
      <label>School Name</label>
      <TextField
        name="profile[education[]school][schoolname]"
        id="profile_education_school_schoolname"
        value={schoolname}
        updateFunc={updateSchool}
      />
      <label>Qualification</label>
      <TextField
        name="profile[education[]school][qualification]"
        id="profile_education_job_school_qualification"
        value={qualification}
        updateFunc={updateQual}
      />
      <label>Date</label>
      <TextField
        name="profile[education[]school][date]"
        id="profile_education_school_date"
        value={date}
        updateFunc={updateDate}
      />
      <label>Description</label>
      <LexicalRichTextareaEditor
        editorState={desc || ''}
        onChangeDescription={updateDesc}
      />
    </div>
  );
};

// ...existing move helper function...

export default ProfileEducation;
