import React, { useState, useEffect } from "react";
import axios from "axios";
import { SBChatShow, SBChatChannel } from "./SBChatStore";

const SBSendMessage: React.FC<{ profileId: string }> = ({ profileId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [csrfToken, setCsrfToken] = useState<string | null>(null);

  useEffect(() => {
    const token = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]',
    )?.content;
    setCsrfToken(token || null);
  }, []);

  const handleCreateChat = async () => {
    if (!csrfToken) {
      setError("CSRF token not found. Please refresh the page.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/profiles/${profileId}/create_chat.json`,
        {},
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        },
      );
      SBChatShow.set(true);
      SBChatChannel.set(response.data.channel_url);
    } catch (error) {
      console.error("Error creating chat:", error);
      setError("Failed to create chat. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <a
        className="popup-with-zoom-anim button orange btn btn-primary btn-rounded col-12"
        onClick={handleCreateChat}
        style={{
          pointerEvents: isLoading ? "none" : "auto",
          cursor: isLoading ? "default" : "pointer",
        }}
      >
        <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
        {isLoading ? "Creating Chat..." : "Send Message"}
      </a>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default SBSendMessage;
