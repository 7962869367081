import * as React from "react";
import { ProfileFormContext } from "../../ProfileEditForm";
import { ACTIONS } from "../ProfileFormReducer";
import { LexicalRichTextareaEditor } from "../../../FormComponents/LexicalRichTextareaEditor";

const BlankExperienceItem = {
  employer: "",
  title: "",
  start_date: "",
  end_date: "",
  current: false,
  description: "",
};

export default function ExperienceHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);
  const [expandedIds, setExpandedIds] = React.useState([]);
  const newItemRef = React.useRef(null);

  const addExperienceItem = () => {
    dispatch({
      type: ACTIONS.ADD_EXPERIENCE,
      payload: BlankExperienceItem,
    });
    setExpandedIds([...expandedIds, state.profile.experiences.length]);
    setTimeout(() => {
      newItemRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const removeExperienceItem = (index: number) => {
    if (window.confirm("Are you sure you want to delete this experience?")) {
      dispatch({
        type: ACTIONS.REMOVE_EXPERIENCE,
        payload: index,
      });
    }
  };

  const updateExperienceItem = (index: number, experience: any) => {
    dispatch({
      type: ACTIONS.UPDATE_EXPERIENCE,
      payload: { index, experience },
    });
  };

  const toggleAccordion = (index) => {
    setExpandedIds((prev) =>
      prev.includes(index) ? prev.filter((id) => id !== index) : [...prev, index]
    );
  };

  return (
    <div className="accordion" id="experienceAccordion">
      {state.profile.experiences.map((experience, index) => (
        <div
          className="accordion-item mb-3 border-0"
          key={index}
          ref={index === state.profile.experiences.length - 1 ? newItemRef : null}
        >
          <h2 className="accordion-header d-flex justify-content-between align-items-center">
            <button
              className={`accordion-button ${!expandedIds.includes(index) ? 'collapsed' : ''} bg-light text-dark`}
              type="button"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: 'pointer' }}
            >
              {experience.title || 'New Experience'} at {experience.employer || 'New Employer'}
            </button>
            <button
              type="button"
              className="btn btn-link p-0 ms-2"
              onClick={(e) => {
                e.stopPropagation();
                removeExperienceItem(index);
              }}
              style={{ color: 'grey' }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </h2>

          <div className={`accordion-collapse collapse ${expandedIds.includes(index) ? 'show' : ''}`}>
            <div className="accordion-body border bg-light">
              <ExperienceItem
                experience={experience}
                updateExperienceItem={(exp) => updateExperienceItem(index, exp)}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={addExperienceItem}>
          Add Experience
        </button>
      </div>
    </div>
  );
}

function ExperienceItem({ experience, updateExperienceItem }) {
  const editorKey = React.useMemo(() => `experience-${experience.id}-${Date.now()}`, []);
  const [experienceState, setExperienceState] = React.useState(experience);

  const handleFieldUpdate = (field, value) => {
    const updatedState = { ...experienceState, [field]: value };
    setExperienceState(updatedState);
    updateExperienceItem(updatedState);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    handleFieldUpdate(name, type === 'checkbox' ? checked : value);
  };

  return (
    <div className="mt-4">
      <div className="form-group">
        <label htmlFor="employer" className="form-label">Employer</label>
        <input
          type="text"
          className="form-control"
          id="employer"
          name="employer"
          value={experienceState.employer}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={experienceState.title}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="start_date" className="form-label">
          Start Date
        </label>
        <input
          type="date"
          className="form-control"
          id="start_date"
          name="start_date"
          value={experienceState.start_date}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="end_date" className="form-label">
          End Date
        </label>
        <input
          type="date"
          className="form-control"
          id="end_date"
          name="end_date"
          value={experienceState.end_date}
          onChange={handleInputChange}
          disabled={experienceState.current}
        />
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="current"
          name="current"
          checked={experienceState.current}
          onChange={handleInputChange}
        />
        <label className="form-check-label" htmlFor="current">
          Current Position
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="description" className="form-label">Description</label>
        <LexicalRichTextareaEditor
          key={editorKey}
          editorState={experienceState.description || ''}
          onChangeDescription={(newHtmlContent) => handleFieldUpdate('description', newHtmlContent)}
        />
      </div>
    </div>
  );
}
